import { AUTH_DO, AUTH_REMOVE } from "../types";

const initialState = { Auth:null }
 
const AuthReducer =(state=initialState, action)=>{
   switch(action.type){
      case AUTH_DO:
         return {
            ...state,
            Auth:action.payload
         };
      case AUTH_REMOVE:
         return {
            ...state,
            Auth:null,
         }
      default:
         return state
   }
 }
 
 
 export default AuthReducer;