import { AUTH_MODAL_REMOVE, AUTH_MODAL_SET } from "../types";

const initialState ={ AM:false }
 
const AuthModalReducer =(state=initialState, action)=>{
   switch(action.type){
      case AUTH_MODAL_SET:
         return {
            ...state,
            AM:true
         };
      case AUTH_MODAL_REMOVE:
         return {
            ...state,
            AM:false,
         }
      default:
         return state
   }
 }
 
 
 export default AuthModalReducer;