import { Link } from 'react-router-dom';
import React, {useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../components/button/primary';
import CardNormal from '../../../components/cards/cardNormal';
import Input from '../../../components/input/input';
import Selector from '../../../components/input/select'
import Colors from '../../../utils/theme/colors';
import useApi from '../../../utils/helper/api';
import useValidation from '../../../utils/helper/validate';
import useAlert from '../../../utils/helper/toast';
import { FiChevronLeft } from 'react-icons/fi';


function InvestorSignup({callback}){
let [busy, setBusy] = useState(false);
let [fname, setFname] = useState({});
let [sname, setSname] = useState({});
let [phone, setPhone] = useState({});
let [email, setEmail] = useState({});
let [country, setCountry] = useState({});
let [pass, setPass] = useState({});
let [cpass, setCPass] = useState({});

let api = useApi();
let validate = useValidation();
let alert = useAlert();
let ref = useRef();
let navigate = useNavigate()
let countries = [] // require("../../../public/data/countries.json");


async function create(){
   let err;
   if(!fname.v || fname.v.length < 2 ){
      fname.e=true; fname.m="Provide a valid firstname"; setFname(JSON.parse(JSON.stringify(fname))); err=true;  }
   else{ fname.e=false; err=false; setFname(JSON.parse(JSON.stringify(fname))); }
   if(!sname.v || sname.v.length < 2 ){
      sname.e=true; sname.m="Provide a valid surname"; setSname(JSON.parse(JSON.stringify(sname))); err=true;  }
   else{ sname.e=false; err=false; setSname(JSON.parse(JSON.stringify(sname))); }
   
   if(!email.v || email.v.length < 5 || !validate.isEmail(email.v)){
      email.e=true; email.m="Provide a valid email address"; setEmail(JSON.parse(JSON.stringify(email))); err=true;
   }
   else{ email.e=false; err=false; setEmail(JSON.parse(JSON.stringify(email))); }
   if(!phone.v || phone.v.length < 7 || !validate.isNumber(phone.v)){
      phone.e=true; phone.m="Provide a valid phone number"; setPhone(JSON.parse(JSON.stringify(phone))); err=true;   }
   else{ phone.e=false; err=false; setPhone(JSON.parse(JSON.stringify(phone))); }
   if(!country.v || country.v.label.length < 2 ){
      country.e=true; country.m="Provide a valid phone number"; setCountry(JSON.parse(JSON.stringify(country))); err=true; }
   else{ country.e=false; err=false; setCountry(JSON.parse(JSON.stringify(country))); }
   let passwordCheck = validate.isValidPassword(pass.v);
   if( passwordCheck !="valid" ){
      pass.e=true; pass.m=passwordCheck; setPass(JSON.parse(JSON.stringify(pass))); err=true;
   }
   else{ pass.e=false; err=false; setPass(JSON.parse(JSON.stringify(pass))); }
   cpass.e=false; setCPass(JSON.parse(JSON.stringify(cpass)));
   if(!cpass.v || pass.v != cpass.v || cpass.v.length < 7 ){
      cpass.e=true; cpass.m="Confirm password incorrect."; setCPass(JSON.parse(JSON.stringify(cpass))); err=true;   }
  
   if(err){
      alert.toast("Fire", "success"); return;
   }
   alert.toast("Nice work!!!", "success");
   setBusy(true)
   navigate("/auth/verify-email")
   //make network request here
}

function goBack(){
   ref.current.classList.remove("fade-in");
   ref.current.classList.add("fade-out");
   setTimeout(()=>callback(), 500)
}
return(
   <div className='fade-in' ref={ref} style={{opacity:0}}>
      <div align="center" style={{margin:"15px 0px", padding:"20px 10px"}}>
         <div className='bold h2' style={{color:Colors.primary}}><b>Signup as Investor</b> </div>
         <div className='regular h5' style={{color:Colors.gray}}>Create account on CreditPeers as an individual investor</div>

         <div className='bold' onClick={goBack} style={{width:"120px", fontSize:"14px", marginTop:"20px", backgroundColor:Colors.primary1, color:Colors.black, padding:"8px 10px", borderRadius:"5px", cursor:"pointer"}}>
            <FiChevronLeft size={16} style={{marginBottom:"-3px", marginRight:"-2px"}} /> <span>Go Back</span>
         </div>

         <div className='row' style={{marginTop:"35px"}} align="left">
            <div className='col-md-6'>
               <Input
                     placeholder={"Enter your firstname"} label="Firstname" bg={Colors.bgShade1}
                     value={fname.v} callback={(v)=>{fname.v=v; setFname(JSON.parse(JSON.stringify(fname)))}}
                     hasError={fname.e} errorMessage={fname.m}
                />
            </div>
            <div className='col-md-6'>
               <Input
                        placeholder={"Enter your surname"} label="Lastname" bg={Colors.bgShade1}
                        value={sname.v} callback={(v)=>{sname.v=v; setSname(JSON.parse(JSON.stringify(sname)))}}
                        hasError={sname.e} errorMessage={sname.m}
                      />
            </div>
         </div>
         <div className='row' style={{marginTop:"15px"}} align="left">
            <div className='col-md-6'>
               <Input
                        placeholder={"Enter your email address"} label="Email" bg={Colors.bgShade1}
                        value={email.v} callback={(v)=>{email.v=v; setEmail(JSON.parse(JSON.stringify(email)))}}
                        hasError={email.e} errorMessage={email.m}
                      />
            </div>
            <div className='col-md-6'>
               <Input
                        placeholder={"Enter your phone number"} label="Phone" bg={Colors.bgShade1} type="number"
                        value={phone.v} callback={(v)=>{phone.v=v; setPhone(JSON.parse(JSON.stringify(phone)))}}
                        hasError={phone.e} errorMessage={phone.m}
                      />
            </div>
         </div>

         <div style={{marginTop:"20px"}} align="left">
               <Selector
                  placeholder={"Select Country"} label="Country" list={countries}
                  value={country.v} callback={(v)=>{country.v=v; setCountry(JSON.parse(JSON.stringify(country)))}}
                  hasError={country.e} errorMessage={country.m}
                />
         </div>

         <div style={{marginTop:"20px"}} align="left">
               <Input
                  placeholder={"Choose password"} label="Create Password" password={true} bg={Colors.bgShade1}
                  value={pass.v} callback={(v)=>{pass.v=v; setPass(JSON.parse(JSON.stringify(pass)))}}
                  hasError={pass.e} errorMessage={pass.m} type={'password'}
                />
         </div>

         <div style={{marginTop:"20px"}} align="left">
               <Input
                     placeholder={"Repeat password to confirm"} label="Confirm Password" password={true} bg={Colors.bgShade1}
                     value={cpass.v} callback={(v)=>{cpass.v=v; setCPass(JSON.parse(JSON.stringify(cpass)))}}
                     hasError={cpass.e} errorMessage={cpass.m} type={'password'}
                />
         </div>

                  
                  <div style={{margin:"30px 0px 10px 0px", }} align="left">
                     <PrimaryButton
                        label={"Create Account"} busy={busy} callback={create}
                      />
                  </div>
                  <div align="center" style={{margin:"15px 0px", marginTop:"20px"}}>
                     <div className='bold h6'>Have an account?</div>
                     <Link to={"/"}>
                        <div className='h6' style={{color:Colors.primary, cursor:"pointer",  marginTop:"5px"}}>Login here</div>
                     </Link>
                  </div>
               </div>
           
     
   </div>
)
}
export default InvestorSignup;