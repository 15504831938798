import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminProductsView from '../../views/admin/products';


function AdminProductsPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminProductsView}  />
   </div>
)
}
export default AdminProductsPage;