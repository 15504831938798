import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminPasswordForgotView from '../../views/auth/admin/forgot';


function AdminForgotPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminPasswordForgotView} title={"Forgot Password"} />
   </div>
)
}
export default AdminForgotPage;