
import React, {useEffect, useState} from "react";
import { Timeline } from 'primereact/timeline';
import { useParams } from "react-router-dom";
import Colors from "../../utils/theme/colors";
import CommunityMenu from "./components/menus";
import CommunityActivityItem from "./components/act-item";
import useValidation from '../../utils/helper/validate'
import { MdCopyAll, MdSavings } from "react-icons/md";

const activity_types = ['new_member', 'member_removed', 'member_leave', 'group_creation', 'new_order', 'order_fulfiled', 'new_contribution', 'group_details_change'];

const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]


function CommunityContributions(){

let params = useParams();
let validate = useValidation();

function Marker(){

return(
    <div className="flex-center" style={{width:"26px", height:'26px', borderRadius:"50%", backgroundColor:Colors.primary1}}>
        <MdSavings size={14} color={Colors.primary} />
    </div>
)
}
return (
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"30px"}} >
            <CommunityMenu />
        </div>
        <div className="col-md-9 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px"}}>
            <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left' }}>
                <div className="row no-margin">
                    <div className="col-md-6 no-padding-on-mobile" style={{padding:"0px 7px 0px 10px", marginBottom:"20px"}}>
                        <div className="bold h2" style={{width:"100%", height:"60px", borderRadius:'1px', backgroundColor:Colors.bg2, padding:"15px 12px", display:'flex', justifyContent:'center', color:Colors.black, flexDirection:'column'}}>
                            {"\u20A6"}{validate.toLocal(2424299.48)}
                            <div className="h7 regular" style={{color:Colors.black, marginTop:'3px'}}>Community Purse | Available Balance</div>
                        </div>
                    </div>
                    <div className="col-md-6 no-padding-on-mobile" style={{padding:"0px 0px 0px 7px", }}>
                        <div className="bold h4" style={{width:"100%", height:"60px", borderRadius:'1px', backgroundColor:Colors.bg2, padding:"16px 12px", display:'flex', justifyContent:'center', color:Colors.black, flexDirection:'column'}}>
                            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                {9242009823}
                                <MdCopyAll className="primary-color" title="Copy account number" style={{marginLeft:"6px", cursor:'pointer'}} />
                            </div>
                            <div className="h7 regular" style={{color:Colors.black, marginTop:'3px'}}>Community's Account Name | Providus Bank</div>
                        </div>
                    </div>
                </div>

                <div className='bold h5 no-padding-on-mobile' style={{color:Colors.black, textDecoration:"underline", padding:"0px 10px 20px 10px ", marginTop:"25px"}}>
                    Contributions: 
                </div>

                <div className="no-padding-on-mobile" style={{paddingLeft:"10px", marginTop:'25px'}}>
                    <Timeline align="left" value={activity_types} 
                        content={(item)=>CommunityActivityItem(item, true)} marker={Marker} />
                </div>
            </div>
        </div>
    </div>
    <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.7rem 0.5rem;  }
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>
</div>
)
}
export default CommunityContributions;


