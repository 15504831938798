
import React, {useEffect, useState} from "react";

import Colors from "../../../utils/theme/colors"
import Input from "../../../components/input/input";
import PrimaryButton from "../../../components/button/primary";

function AccountSecurity(){
let [oldp, setOldp] = useState({})
let [newp, setNewp] = useState({})
let [confirmp, setConfirmp] = useState({})

return(
    <div>
        <div className="bold h4" style={{textDecoration:"underline", marginBottom:'10px'}}>Change account password</div>
        <div>
            <div style={{marginTop:"20px"}} align="left">
                     <Input
                        placeholder={"Enter current password"} label="CurrentPassword" password={true} bg={Colors.bgShade1}
                        value={oldp.v} callback={(v)=>{oldp.v=v; setOldp(JSON.parse(JSON.stringify(oldp)))}}
                        hasError={oldp.e} errorMessage={oldp.m} type="password" label_fw={'normal'}
                      />
            </div>

            <div className="row no-margin">
                <div className="col-md-6 no-padding-on-mobile" style={{marginTop:"20px", padding:"0px 10px 0px 0px"}} >
                     <Input
                        placeholder={"Enter new password"} label="New Password" password={true} bg={Colors.bgShade1}
                        value={newp.v} callback={(v)=>{newp.v=v; setNewp(JSON.parse(JSON.stringify(newp)))}}
                        hasError={newp.e} errorMessage={newp.m} type="password" label_fw={'normal'}
                      />
                  </div>
                  <div className="col-md-6 no-padding-on-mobile" style={{marginTop:"20px", padding:'0px 0px 0px 10px'}} >
                     <Input label_fw={'normal'}
                        placeholder={"Enter repeat new password"} label=" Confirm Password" password={true} bg={Colors.bgShade1}
                        value={confirmp.v} callback={(v)=>{confirmp.v=v; setConfirmp(JSON.parse(JSON.stringify(confirmp)))}}
                        hasError={confirmp.e} errorMessage={confirmp.m} type="password"
                      />
                  </div>
            </div>

            <div>
                <PrimaryButton label={"Change Password"} callback={()=>0} br="0px" />
            </div>
        </div>
    </div>
)
}
export default AccountSecurity;