
import React, {useEffect, useState} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/button/primary';
import MainSideContentModal from "../../components/modal/slidemodal";
import Input from '../../components/input/input';
import Selector from '../../components/input/select';
import TextArea from '../../components/input/textarea'
import Colors from "../../utils/theme/colors"
import useValidation from '../../utils/helper/validate';


const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]

const locations_ = [
    {label:"Ibadan", value:'ibadan'}, {label:"Lagos", value:'lagos'}, {label:"Abuja", value:'abuja'}, 
]


function AdminVendorsView(){
let [open, setOpen] = useState(false)
let [name, setName] = useState({})
let [location, setLocation] = useState({})
let [address, setAddress] = useState({})
let [phone, setPhone] = useState({})
let [email, setEmail] = useState({})
let [notes, setNotes] = useState({})


let validate = useValidation();


async function createVedor(){}
return(
<>
    <div className='inner-content-bag'>
       
      <div className='row no-margin' style={{marginTop:"20px", width:'100%', display:'flex', alignItems:'flex-start', justifyContent:'flex-start', flexWrap:'wrap', clear:'both'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Ibadan Vendors</span>
                </div>
        </div>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 10px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Lagos Vendors</span>
                </div>
        </div>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 10px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Abuja Vendors</span>
                </div>
        </div>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px", marginBottom:"20px"}}>
            <div className='no-margin-on-mobile' style={{marginTop:"-10px"}}>
                <PrimaryButton
                    label={'Create New Vendor'} callback={()=>setOpen(true)} br="0px" width={'100%'}
                />
            </div>
        </div>
      </div>

        <div className="no-padding-on-mobile" style={{paddingLeft:'0px', marginTop:'20px', width:"100%"}}>
                    <DataTable 
                        value={items_} showGridlines size="small" stripedRows rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Name" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Location" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Address" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="price" header="Contact" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="medium">{"\u20A6"}{validate.toLocal(p?.price)}</div>)
                            }}
                        ></Column>
                        <Column field="qty" header="Orders Fulfiled" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="qty" header="Status" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head f-w'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}} 
                            body ={(c)=>
                                <Link to="/ad-management/vendors/0242422" style={{color:Colors.primary}}>View</Link>
                            }
                            ></Column>
                    </DataTable>
        </div>

   </div>
   <MainSideContentModal open={open} close={()=>setOpen(false)}
        title={'Create New Vendor'} buttonTitile={"Create Vendor"} useButton buttonCallback={createVedor}
        >
        <div style={{padding:"10px 12px", color:Colors.black}}>
            <div>
                <Input
                        placeholder={"Enter vendor name"} label="Vendor Name" bg={Colors.bgShade1} 
                        value={name.v} callback={(v)=>{name.v=v; setName(JSON.parse(JSON.stringify(name)))}}
                        hasError={name.e} errorMessage={name.m}
                 />
            </div>
            <div style={{marginTop:"12px"}} align="left">
                <Selector list ={locations_}
                        placeholder={"Select Location"} label="Location" bg={Colors.bgShade1} 
                        value={location.v} callback={(v)=>{location.v=v; setLocation(JSON.parse(JSON.stringify(location)))}}
                        hasError={location.e} errorMessage={location.m}
                 />
            </div>
            <div style={{marginTop:"15px"}} align="left">
                     <Input
                        placeholder={"Enter vendor phone number"} label="Vendor Phone Number" bg={Colors.bgShade1} type="number"
                        value={phone.v} callback={(v)=>{phone.v=v; setPhone(JSON.parse(JSON.stringify(phone)))}}
                        hasError={phone.e} errorMessage={phone.m}
                      />
            </div>
            <div style={{marginTop:"15px"}} align="left">
                     <Input
                        placeholder={"Enter email"} label="Email" bg={Colors.bgShade1} type="email"
                        value={email.v} callback={(v)=>{email.v=v; setEmail(JSON.parse(JSON.stringify(email)))}}
                        hasError={email.e} errorMessage={email.m}
                      />
            </div>
            <div style={{marginTop:"15px"}} align="left">
                     <TextArea
                        placeholder={"Enter vendor address"} label="Vendor Address" bg={Colors.bgShade1} 
                        value={address.v} callback={(v)=>{address.v=v; setAddress(JSON.parse(JSON.stringify(address)))}}
                        hasError={address.e} errorMessage={address.m}
                      />
            </div>
            <div style={{marginTop:"15px"}} align="left">
                     <TextArea
                        placeholder={"Enter notes"} label="Additional Notes" bg={Colors.bgShade1} 
                        value={notes.v} callback={(v)=>{notes.v=v; setNotes(JSON.parse(JSON.stringify(notes)))}}
                        hasError={notes.e} errorMessage={notes.m}
                      />
            </div>
             

            
        </div>
    </MainSideContentModal>
   <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.9rem 0.5rem;  }
    .tr-body {padding:0.7rem 0.5rem; }
    .f-w {max-width:50px}
    `}</style>
</>
)
}
export default AdminVendorsView