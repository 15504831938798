import React, {useState, useEffect} from 'react';

import useColors from '../theme/useColor';
import AuthenticationManager from './authmanager';
import AdminParentNavigation from './ad-parentnav';


function AdminPageBuilder({PageComponent, title, pageProps, Access, Deny}){
let [auth, setAuth] = useState(null);
let colors = useColors();
let headerless = [ "ad-management/auth/forgot-password", "ad-management/auth/password-otp", "ad-management/auth/reset-password"];


useEffect(()=>{
   let path = window.location.pathname;
   setAuth( path=="/ad-management" ? false : !headerless.includes(path.replace('/', '')))
}, []);

return(
<>
    

   <div style={{width:"100%", minHeight:"100vh", backgroundColor:colors.bg1, margin:"0px", padding:"0px"}}>
      {
         auth==null ? null :
         auth == false ? <PageComponent {...pageProps} />:
         auth == true ? 
            <AdminParentNavigation >
               <AuthenticationManager allow = {Access} deny = {Deny} >
                  <PageComponent {...pageProps} />
               </AuthenticationManager>
            </AdminParentNavigation>
         :null
      }
   </div>

</>
)
}
export default AdminPageBuilder;