import AdminOrdersPage from "../pages/admin/orders_";
import AdminOrderDetailsPage from "../pages/admin/order_d";
import AdminVendorsPage from "../pages/admin/vends-p";
import AdminForgotPage from "../pages/auth/ad-forgot";
import AdminLoginPage from "../pages/auth/ad-login";
import AdminPasswordOTPPage from "../pages/auth/ad-recovery-otp";
import AdminPasswordResetPage from "../pages/auth/ad-reset";
import AdminDashboardPage from "../pages/dashboard/ad-dash";
import AdminVendorDetailsPage from '../pages/admin/vend-p'
import AdminPersonalAccountPage from '../pages/admin/account'
import AdminProductsPage from '../pages/admin/prods'
import AdminProductDetailsPage from "../pages/admin/prod";
import AdminMorePage from "../pages/admin/more-p";






const base_ = "/ad-management"
const AdminRoutes = [
    {  path: base_+"",                          element: <AdminLoginPage />   },
    {  path: base_+"/auth/forgot-password",      element: <AdminForgotPage />  },
    {  path: base_+"/auth/password-otp",         element: <AdminPasswordOTPPage />    },
    {  path: base_+"/auth/reset-password",      element: <AdminPasswordResetPage />  },

    {  path: base_+"/dashboard/account",      element: <AdminPersonalAccountPage />  },
    {  path: base_+"/dashboard",      element: <AdminDashboardPage />  },
    {  path: base_+"/orders",      element: <AdminOrdersPage />  },
    {  path: base_+"/orders/:order_id",      element: <AdminOrderDetailsPage />  },
    {  path: base_+"/vendors",      element: <AdminVendorsPage />  },
    {  path: base_+"/vendors/:order_id",      element: <AdminVendorDetailsPage />  },
    {  path: base_+"/products",      element: <AdminProductsPage />  },
    {  path: base_+"/products/:product_id",      element: <AdminProductDetailsPage />  },
    {  path: base_+"/more",      element: <AdminMorePage />  },
]

export default AdminRoutes;