import CommunityActivityPage from "../pages/community/act-p";
import CommunityOrdersPage from "../pages/community/orders";
import CommunityMembersPage from "../pages/community/memba";
import CommunityContributionsPage from "../pages/community/contribute";
import CommunityAboutPage from "../pages/community/abt";
import DashboardPage from "../pages/dashboard";
import AccountPage from "../pages/dashboard/accounthm";
import MarketPlacePage from "../pages/marketplace/market";
import MarketPlaceCartPage from "../pages/marketplace/cart";
import MarketPlaceCheckoutPage from "../pages/marketplace/checkout";
import CommunityOrderDetailsPage from "../pages/community/order-d";


const AuthenticatedRoutes = [
    {
        path: "/dashboard",
        element: <DashboardPage />,
    },
    {
        path: "/dashboard/community/:cid",
        element: <CommunityActivityPage />,
    },
    {
        path: "/dashboard/community/:cid/orders",
        element: <CommunityOrdersPage />,
    },
    {
        path: "/dashboard/community/:cid/orders/:order_id",
        element: <CommunityOrderDetailsPage />,
    },
    {
        path: "/dashboard/community/:cid/contributions",
        element: <CommunityContributionsPage />,
    },
    {
        path: "/dashboard/community/:cid/members",
        element: <CommunityMembersPage />,
    },
    {
        path: "/dashboard/community/:cid/about",
        element: <CommunityAboutPage />,
    },
    {
        path: "/marketplace",
        element: <MarketPlacePage />,
    },
    {
        path: "/marketplace/cart",
        element: <MarketPlaceCartPage />,
    },
    {
        path: "/marketplace/checkout",
        element: <MarketPlaceCheckoutPage />,
    },
    {
        path: "/account",
        element: <AccountPage />,
    },
];

export default AuthenticatedRoutes;