import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import MarketPlaceCheckout from '../../views/market/checkout';


function MarketPlaceCheckoutPage(){
return(
   <div>
      <PageBuilder PageComponent={MarketPlaceCheckout} title={"MarketPlace"} />
   </div>
)
}
export default MarketPlaceCheckoutPage;