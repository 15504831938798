import React, {useEffect, useState} from 'react';
import PrimaryButton from '../../components/button/primary';
import Colors from '../../utils/theme/colors';
import CommunityListingItem from './components/comm-item';

const communities_ = [
   {photo:null, name:"Whitehouse Community", id:"2424204", started_by:"Temilade", starter_photo:null, member_count:12},
   {photo:null, name:"Bluehouse Community", id:"2424204", started_by:"Temilade", starter_photo:null, member_count:12},
   {photo:null, name:"Yellowhouse Community", id:"2424204", started_by:"Temilade", starter_photo:null, member_count:12},
   {photo:null, name:"Whitehouse Community", id:"2424204", started_by:"Temilade", starter_photo:null, member_count:12},
   {photo:null, name:"Whitehouse Community", id:"2424204", started_by:"Temilade", starter_photo:null, member_count:12},
]

function AppDashboard(){

return(
<>
   <div className='inner-content-bag'>
      <div className='flex-center' style={{justifyContent:'space-between', width:'100%'}}>
         <div className='bold h3' style={{color:Colors.black, textDecoration:"underline"}}>Your Communities:</div>
         <PrimaryButton label={"Create New Community"} callback={()=>0} br="0px" />
      </div>
      <br />
      <div className='row no-margin' style={{marginTop:"20px", width:'100%', display:'flex', alignItems:'flex-start', justifyContent:'flex-start', flexWrap:'wrap', clear:'both'}}>
         {
            communities_.map((c, i)=>
               <div key={i} className="col-md-4 no-padding-on-mobile" 
                  style={{paddingRight:i%3==2 ? "0px": "15px", paddingLeft:"0px", marginBottom:'30px'}}>
                  <CommunityListingItem com = {c} /> 
               </div>
            )
         }
      </div>
   </div>
</>
)
}
export default AppDashboard;