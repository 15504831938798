import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import SignupView from '../../views/auth/register';


function SignupPage(){
return(
   <div>
      <PageBuilder PageComponent={SignupView} title={"Signup"} />
   </div>
)
}
export default SignupPage;