
import React, {useEffect, useState} from "react"
import { MdOutlineVerified, MdVerifiedUser } from "react-icons/md";

import Colors from "../../../utils/theme/colors"
import IdentityBVN from "./bvn";
import IdentitySelfie from "./selfie";
import IdentityStart from "./start";


function AccountVerification(){
let [screen, setScreen] = useState(null);

useEffect(()=>{
    setScreen('start')
}, [])

return(
    <div>
        {screen=="start"  && <IdentityStart callback={()=>setScreen('selfie')} /> }
        {screen=="selfie"  && <IdentitySelfie callback={()=>setScreen('bvn')} /> }
        {screen=="bvn"  && <IdentityBVN callback={()=>0} /> }
        {screen=="verified"  && 
            <div className="no-padding-on-mobile" style={{marginTop:'1em', paddingTop:'2em'}}>
                <div className="flex-center">
                    <div className="flex-center" style={{width:'100px', height:'100px', borderRadius:'50px', backgroundColor:Colors.primary1}}>
                        <MdOutlineVerified size={45} color={Colors.primary} />
                    </div>
                </div>
                <div className="h5" style={{marginTop:'15px', textAlign:'center', color:Colors.primary}}>Your account is verified</div>
            </div>
        }
    </div>
)
}
export default AccountVerification