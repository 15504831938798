
import React, {useEffect, useState} from "react";
import { Timeline } from 'primereact/timeline';
import { useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import OrderMenu from "./components/ord-menu";
import Colors from "../../utils/theme/colors";
import useValidation from "../../utils/helper/validate";
import PrimaryButton from "../../components/button/primary";
import Selector from '../../components/input/select'
import { MdCheck } from "react-icons/md";

const activity_types = ['recived', 'preparing order', 'on route for delivery', 'order delivered'];
const status_list = [
    {label:"Order Recieved", value:'received'},
    {label:"Preparing Order", value:'received'},
    {label:"Order Enroute for delivery", value:'enroute'},
    {label:"Order Delivered", value:'delivered'},
];
const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]


function AdminOrderDetailsView(){
let [open, setOpen] = useState(false)
let params = useParams();
let validate = useValidation();

return(
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"30px"}} >
            <div style={{width:"100%",textAlign:'left', color:Colors.black, }}>
                <div className='bold h5' style={{ textDecoration:"underline",marginBottom:"20px"}}>Delivery: Ibadan</div>
                <div className="h6 medium">Temilade Olowolagba</div>
                <div className="h6" style={{margin:"10px 0px", textDecoration:"underline", }}>09024013011</div>
                <div className="h7">Detailed address comes here. It really must be detailed in deed</div>
            </div>
        </div>
        <div className="col-md-9 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px"}}>
            <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left' }}>
                <div className='bold h2' style={{color:Colors.black, textDecoration:"underline", marginBottom:'20px', padding:"0px 10px 10px 10px"}}>
                    Order: #{params?.order_id} | <span className="h6">Datetime</span>
                </div>
                <div className="row no-margin" style={{justifyContent:'space-between',}}>
                    <div className="col-md-4 no-padding-on-mobile" style={{padding:"0px 10px", }}>
                        <div className="bold h2" style={{width:"100%", height:"50px", borderRadius:'5px', backgroundColor:Colors.bg2, padding:"12px", display:'flex', justifyContent:'center', color:Colors.black, flexDirection:'column'}}>
                            {"\u20A6"}{validate.toLocal(2424299.48)}
                            <div className="h7 regular" style={{color:Colors.black}}>Total order amount</div>
                        </div>
                    </div>
                    <div className="col-md-5 no-padding-on-mobile" style={{padding:"0px 0px 0px 7px", }}>
                       <div style={{marginTop:"-10px"}}>
                            <PrimaryButton
                                label={"Complete Order Fulfilment"} width="100%" br={'0px'}
                                Icon = {MdCheck} callback={()=>setOpen(true)}
                            />
                       </div>
                    </div>
                </div>
                <div className='bold h5 no-padding-on-mobile' style={{color:Colors.black, textDecoration:"underline", padding:"0px 10px", margin:'25px 0px'}}>
                    Order Items: 
                </div>
                <div className="no-padding-on-mobile" style={{paddingLeft:'10px'}}>
                    <DataTable 
                        value={items_} showGridlines size="small" stripedRows rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular"headerClassName={'h6 bold table-head'}
                            headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Item" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Price" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="price" header="Price" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="medium">{"\u20A6"}{validate.toLocal(p?.price)}</div>)
                            }}
                        ></Column>
                        <Column field="qty" header="Quantity" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="total" header="Total Amount" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="bold">{"\u20A6"}{validate.toLocal(p?.total)}</div>)
                            }}
                            headerStyle={{borderTopRightRadius:"2px"}}></Column>
                        <Column field="qty" header="Vendor" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                    </DataTable>
                </div>

                <div className='bold h5 no-padding-on-mobile' style={{color:Colors.black, textDecoration:"underline", padding:"20px 10px ", marginTop:"25px"}}>
                    Processing Status: (Current Status)
                </div>

                <div className='no-padding-on-mobile' style={{color:Colors.black, padding:"0px 10px "}}>
                    <div>
                        Update order processing status below
                    </div>
                    <div className="col-md-4 no-padding" style={{margin:'20px 0px'}}>
                        <Selector list={status_list} callback={()=>0} />
                    </div>
                    <div>
                        <PrimaryButton size={'small'} label="Update Processing Status" callback={()=>0} />
                    </div>

                    <div className="no-padding-on-mobile" style={{paddingLeft:"10px", marginTop:'25px'}}>
                    </div>
                </div>

                
            </div>
        </div>
    </div>
    <Dialog visible={open} closeOnEscape  onHide={()=>setOpen(false)}>
         <div >
            <div className='bold h4' style={{color:Colors.black}}>Do you want to mark this order as completely fulfiled?</div>
            <div>Ensure the order has been delivered and confirmed before marking this as closed and complete</div>
            <div style={{marginTop:"30px"}}>
               <PrimaryButton label={"No, Cancel"} callback={()=>setOpen(false)}
                  br="0px" bg={Colors.bgShade1} color={Colors.black}  width="100%" border={"1px solid gray"}
                />
            </div>

            <div style={{marginTop:"20px"}}>
               <PrimaryButton
                  label={"Yes, Order has been fulfiled!"} callback={()=>0} width="100%" br="0px"
                />
            </div>
         </div>
      </Dialog>
    <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.7rem 0.5rem;  }
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>
</div>
)
}
export default AdminOrderDetailsView