import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminVendorDetailsView from '../../views/admin/vendor';


function AdminVendorDetailsPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminVendorDetailsView}  />
   </div>
)
}
export default AdminVendorDetailsPage;