import React, {useState} from "react";
import { Tooltip } from 'react-tooltip'
import { FiCamera } from "react-icons/fi";
import Colors from "../../../utils/theme/colors";
import Input from "../../../components/input/input";
import Selector from "../../../components/input/select";
import TextArea from "../../../components/input/textarea";
import MainSideContentModal from "../../../components/modal/slidemodal";


const locations_ = [
    {label:"Ibadan", value:'ibadan'}, {label:"Lagos", value:'lagos'}, {label:"Abuja", value:'abuja'}, 
]


function AdminCreateProduct({open, close}){
let [name, setName] = useState({})
let [location, setLocation] = useState({})
let [address, setAddress] = useState({})
let [phone, setPhone] = useState({})
let [cat, setCat] = useState({})
let [subcat, setSubcat] = useState({})
let [email, setEmail] = useState({})
let [notes, setNotes] = useState({})
let [image, setImage] = useState(null);



const handleImage=async(e)=>{ 
    if(e.target.files[0]==undefined){ return; }
    let name =e.target.files[0].name.split('.');
    let ext=name[name.length - 1]; 
    let size = Math.round(e.target.files[0].size/1024); 
    if(size > 500){ alert.toast("Image size limit exceeded. Maximum size allowed is 500KB", 'error');  return; }
    const reader = new FileReader(); 
      reader.readAsDataURL(e.target.files[0]); 
      reader.onload=()=>{ 
        var img = new Image(); 
        img.onload=()=>{ 
          let r= { ext:ext, base64:reader.result, }; image= r; setImage(JSON.parse(JSON.stringify(image)))
        }
        img.src=reader.result;
      };
 }

async function createProduct(){}


return (
<>
    <MainSideContentModal open={open} close={close} sy='scroll'
        title={'Create New Product'} buttonTitile={"Create Product"} useButton buttonCallback={createProduct}
        >
        <div style={{padding:"10px 12px", color:Colors.black}}>
            <div>
                <Input
                        placeholder={"Enter product name"} label="Product Name" bg={Colors.bgShade1} 
                        value={name.v} callback={(v)=>{name.v=v; setName(JSON.parse(JSON.stringify(name)))}}
                        hasError={name.e} errorMessage={name.m}
                 />
            </div>
            <div style={{marginTop:"12px"}} align="left">
                    <TextArea
                        placeholder={"Enter product description"} label="Product Description" bg={Colors.bgShade1} 
                        value={notes.v} callback={(v)=>{notes.v=v; setNotes(JSON.parse(JSON.stringify(notes)))}}
                        hasError={notes.e} errorMessage={notes.m}
                      />
            </div>
            <div style={{marginTop:"12px"}} align="left">
                <Selector list ={locations_}
                        placeholder={"Select Location"} label="Available Location" bg={Colors.bgShade1} multi
                        value={location.v} callback={(v)=>{location.v=v; setLocation(JSON.parse(JSON.stringify(location)))}}
                        hasError={location.e} errorMessage={location.m}
                 />
            </div>
            <div style={{marginTop:"12px"}} align="left">
                <Selector list ={locations_}
                        placeholder={"Select Category"} label="Product Category" bg={Colors.bgShade1} 
                        value={cat.v} callback={(v)=>{cat.v=v; setCat(JSON.parse(JSON.stringify(cat)))}}
                        hasError={cat.e} errorMessage={cat.m}
                 />
            </div>
            <div style={{marginTop:"12px"}} align="left">
                <Selector list ={locations_}
                        placeholder={"Select Subcategory"} label="Product Subcategory" bg={Colors.bgShade1} 
                        value={subcat.v} callback={(v)=>{subcat.v=v; setSubcat(JSON.parse(JSON.stringify(subcat)))}}
                        hasError={subcat.e} errorMessage={subcat.m}
                 />
            </div>
            <div style={{marginTop:"15px"}} align="left">
                     <Input
                        placeholder={"Enter product tags"} label="Product Tags" bg={Colors.bgShade1}
                        value={phone.v} callback={(v)=>{phone.v=v; setPhone(JSON.parse(JSON.stringify(phone)))}}
                        hasError={phone.e} errorMessage={phone.m}
                      />
            </div>

            <div style={{marginTop:"15px"}}>
                <div className="bold h6" style={{marginBottom:"12px"}}>Add Product Image</div>
                <input id="itemimage" style={{display:'none'}} type="file" onChange={handleImage} />
                <div style={{textAlign:'left', marginBottom:'20px'}}>
                    <div 
                    onClick={()=>document.getElementById("itemimage").click()}
                    data-tooltip-id="product-photo-tip" data-tooltip-content={"Tap to upload/change product picture"}
                    data-tip="Tap to change profile picture" style={{width:"100px", height:"100px", borderRadius:"0%", cursor:"pointer", display:"flex", alignItems:'center', justifyContent:'center', backgroundColor:Colors.bgShade1}}>
                    {image !==null ?
                        <img className="img-regular" src={image.base64} style={{height:"100px", width:"100px", borderRadius:"0%"}} />
                    : 
                        <FiCamera size={22} color={Colors.primary} />
                    }
                    </div>
                </div>
            </div>
            
        </div>
        <Tooltip id="product-photo-tip" />
    </MainSideContentModal>
</>
)
}
export default AdminCreateProduct;
