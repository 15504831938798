import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import MarketPlaceCart from '../../views/market/cart';


function MarketPlaceCartPage(){
return(
   <div>
      <PageBuilder PageComponent={MarketPlaceCart} title={"Dashboard"} />
   </div>
)
}
export default MarketPlaceCartPage;