import React from "react";
import { FaPeopleArrows } from "react-icons/fa";
import { Link } from "react-router-dom";
import Colors from "../../../utils/theme/colors";


function CommunityListingItem({com}){
return(
    <Link to={"/dashboard/community/"+com?.id}
        className="add-box-shadow"
        style={{width:'100%', display:'block', borderRadius:'5px', cursor:'pointer', overflow:'hidden'}}>
            <div style={{height:'130px', position:'relative', backgroundColor:Colors.bg2, display:'flex', alignItems:'center', justifyContent:'center' }}>
                {
                    com?.photo? 
                    <img src={com?.photo} style={{width:"100%"}} className="img-regular" />
                    :
                    <div style={{width:"50px", height:'50px', borderRadius:"50%", backgroundColor:Colors.primary, alignItems:'center', justifyContent:'center', display:'flex' }}>
                        <FaPeopleArrows color={Colors.bgLight} size={22} />
                    </div>
                }
                <div className="flex-center" style={{position:'absolute', bottom:'8px',}}>
                    <div className="h6 medium" style={{backgroundColor:"#00a75830", color:Colors.primary, borderRadius:'5px', padding:'3px 10px' }}>
                        {com?.member_count} members
                    </div>
                </div>
            </div>
        <div style={{padding:"18px 10px", textAlign:'left'}}>
            <div className="bold h4" style={{color:Colors.black}} >{com?.name}</div>
        </div>
    </Link>
)
}

export default CommunityListingItem
