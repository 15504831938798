import { applyMiddleware, combineReducers } from 'redux';
import { configureStore,  } from "@reduxjs/toolkit";
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { thunk } from "redux-thunk";
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { RESET_STORE } from '../types';
import rootReducer from '../reducers/rootReducer';


const persistConfig = {
   key: 'root',
   storage: storage,
   stateReconciler: hardSet,
 };

const persistedReducer = persistReducer(persistConfig, rootReducer);

const AppStore= configureStore({
     reducer: persistedReducer,
     middleware:(getDefaultMiddleware)=>getDefaultMiddleware(),
   })
export default AppStore;
export const persist_store = persistStore(AppStore);

