
import React from "react";
import { Timeline } from 'primereact/timeline';
import { HiViewGridAdd } from "react-icons/hi";
import {MdPersonAdd, MdOutlineGroupRemove, MdAddShoppingCart, MdOutlineShoppingCart } from 'react-icons/md'
import { IoPersonRemoveOutline } from "react-icons/io5";
import { GiPayMoney } from "react-icons/gi";
import { TbExchange } from "react-icons/tb";
import CommunityMenu from "./components/menus";
import Colors from "../../utils/theme/colors";
import CommunityActivityItem from "./components/act-item";


const activity_types = ['started by | Start date', "Community Photo" ];

function CommunityAbout(){

function Marker(){

let MarkerIcon = {
    'new_member':MdPersonAdd, 
    'member_removed':IoPersonRemoveOutline, 
    'member_leave':MdOutlineGroupRemove, 
    'group_creation':HiViewGridAdd, 
    'new_order':MdAddShoppingCart, 
    'order_fulfiled':MdOutlineShoppingCart, 
    'new_contribution':GiPayMoney, 
    'group_details_change':TbExchange
}

return(
    <div className="flex-center" style={{width:"26px", height:'26px', borderRadius:"50%", backgroundColor:Colors.primary1}}>
        <HiViewGridAdd size={14} color={Colors.primary} />
    </div>
)
}
return (
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"30px"}} >
            <CommunityMenu />
        </div>
        <div className="col-md-9 no-padding-on-mobile" style={{padding:"0px 0px 0px 20px"}}>
            <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left' }}>
                <div style={{width:"100%", borderRadius:'1px', marginBottom:"20px", backgroundColor:Colors.bg2, padding:"15px 12px"}}>
                    <div className="bold h5" style={{ display:'flex', alignItems:'center', color:Colors.black, }}>
                        Community Name | 
                        <span className="h6 regular" style={{marginTop:"0px", marginLeft:'5px'}}>12 Members</span>
                    </div>
                    <div className="h6 regular" style={{color:Colors.black, marginTop:'10px' }}>
                        Community about/description
                    </div>
                </div>
            </div>
            <div className="no-padding-on-mobile" style={{marginTop:"40px"}}>
                <Timeline align="left" value={activity_types} content={CommunityActivityItem} marker={Marker} />
            </div>
        </div>
    </div>
    <style>{`
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>
</div>
)
}
export default CommunityAbout;


