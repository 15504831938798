import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import EmailVerificationView from '../../views/auth/email-otp';


function VerifyEmailPage(){
return(
   <div>
      <PageBuilder PageComponent={EmailVerificationView} title={"Verify Email "} />
   </div>
)
}
export default VerifyEmailPage;