import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminDashboard from '../../views/admin/dash';


function AdminDashboardPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminDashboard} title={"Dashboard"} />
   </div>
)
}
export default AdminDashboardPage;