
import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CommunityMenu from "./components/menus";
import Colors from "../../utils/theme/colors";
import { MdPersonAdd, } from "react-icons/md";
import PrimaryButton from "../../components/button/primary";
import Input from '../../components/input/input';
import MainSideContentModal from "../../components/modal/slidemodal";

const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]


function CommunityMembers(){
let [open, setOpen] = useState(false)
let [busy, setBusy] = useState(false)
let [fname, setFname] = useState({})
let [lname, setLname] = useState({})
let [email, setEmail] = useState({})

async function sendInvite(){}

return (
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"30px"}} >
            <CommunityMenu />
        </div>
        <div className="col-md-9 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px"}}>
            <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left' }}>
                <div className="row no-margin">
                    <div className="col-md-6 no-padding-on-mobile" style={{padding:"0px 7px 0px 10px", marginBottom:"20px"}}>
                        <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.bg2, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                            <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Community Members</span>
                        </div>
                    </div>
                    <div className="col-md-6 no-padding-on-mobile" style={{padding:"0px 0px 0px 7px", }}>
                       <div style={{marginTop:"-10px"}}>
                            <PrimaryButton
                                label={"Invite New Member"} width="100%" br={'0px'}
                                Icon = {MdPersonAdd} callback={()=>setOpen(true)}
                            />
                       </div>
                    </div>
                </div>

                <div className="no-padding-on-mobile" style={{paddingLeft:'10px', marginTop:'20px'}}>
                    <DataTable 
                        value={items_} showGridlines size="small" stripedRows rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Name" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Email" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="qty" header="Date Joined" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>


    <MainSideContentModal open={open} close={()=>setOpen(false)}
        title="Invite New Member" buttonTitile={"Send Invite"} useButton buttonCallback={sendInvite}
        >
        <div style={{padding:"15px 12px"}}>
            <div>
                <Input
                        placeholder={"Enter firstname"} label="Firstname" bg={Colors.bgShade1} 
                        value={fname.v} callback={(v)=>{fname.v=v; setFname(JSON.parse(JSON.stringify(fname)))}}
                        hasError={fname.e} errorMessage={fname.m}
                 />
            </div>
            <div style={{marginTop:"15px"}} align="left">
                <Input
                        placeholder={"Enter firstname"} label="Firstname" bg={Colors.bgShade1} 
                        value={lname.v} callback={(v)=>{lname.v=v; setLname(JSON.parse(JSON.stringify(lname)))}}
                        hasError={lname.e} errorMessage={lname.m}
                 />
            </div>
            <div style={{marginTop:"15px"}} align="left">
                     <Input
                        placeholder={"Enter email"} label="Email" bg={Colors.bgShade1} type="email"
                        value={email.v} callback={(v)=>{email.v=v; setEmail(JSON.parse(JSON.stringify(email)))}}
                        hasError={email.e} errorMessage={email.m}
                      />
            </div>
        </div>
    </MainSideContentModal>


    <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.9rem 0.5rem;  }
    .tr-body {padding:0.7rem 0.5rem; }
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>

</div>
)
}
export default CommunityMembers;


