import React from 'react';
import AdminBottomNavigation from '../../components/header/ad-bottomnav';
import AdminHeader from '../../components/header/ad-header';
import AdminTopNavigation from '../../components/header/ad-topnav';
import Colors from '../theme/colors';




function AdminParentNavigation({children}){
return(
   <div >
      <div>
         <AdminHeader />
      </div>
      <center>
         <div className='content-body-bag col-md-9'>
            <AdminTopNavigation />
            <div>
               {children}
            </div>
         </div>
      </center>
      <div>
         <AdminBottomNavigation />
      </div>
   </div>
)
}
export default AdminParentNavigation;