import React, {useEffect, useState} from 'react';

import Colors from './colors';



function useColors(){
let [theme, setTheme] = useState("light");

useEffect(()=>{
   if(window){
      let theme_ = localStorage.getItem("app_theme");
      if(theme_){
         setTheme(theme_);
      }
   }
}, []);

return {
   bg1:        theme=="light" ? Colors.bg1 : Colors.bg1_,
   bgLight  :  theme=="light" ? Colors.bgLight : Colors.bgLight_,
}


}
export default useColors;