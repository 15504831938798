import React from "react";
import { FaPeopleArrows } from "react-icons/fa";
import { MdAddShoppingCart, MdNaturePeople } from "react-icons/md";
import { Link } from "react-router-dom";
import Colors from "../../../utils/theme/colors";
import useValidation from "../../../utils/helper/validate";


function ProductListingItem({prod, callback}){
let validate = useValidation();

return(
    <div className="add-box-shadow2" style={{width:'100%', borderRadius:'2px', overflow:'hidden'}}>
        <div style={{height:'130px', position:'relative', backgroundColor:Colors.bg2, display:'flex', alignItems:'center', justifyContent:'center' }}>
                {
                    prod?.photo? 
                    <img src={prod?.photo} style={{width:"100%"}} className="img-regular" />
                    :
                    <div style={{width:"50px", height:'50px', borderRadius:"50%", backgroundColor:Colors.primary, alignItems:'center', justifyContent:'center', display:'flex' }}>
                        <MdNaturePeople color={Colors.bgLight} size={22} />
                    </div>
                }
                <div className="flex-center" style={{position:'absolute', bottom:'8px',}}>
                    <div className="h6 medium" style={{backgroundColor:"#00a75830", color:Colors.primary, borderRadius:'2px', padding:'3px 10px' }}>
                        from {"\u20A6"}{validate.toLocal(prod?.amount)}
                    </div>
                </div>
        </div>
        <div style={{width:'100%', display:'flex', alignItems:'stretch', flexDirection:'row' }}>
            <div style={{padding:"12px 10px", textAlign:'left', width:"calc(100% - 60px)"}}>
                <div className=" h6" style={{color:Colors.black}} >{prod?.name}</div>
            </div>
            <div onClick={callback || null} className="h6" style={{width:'60px', padding:"12px 7px", cursor:'pointer', display:'flex', flexDirection:'row', alignItems:'center', backgroundColor:Colors.primary, color:Colors.bgLight}}>
                <MdAddShoppingCart color={Colors.bgLight} size={16} />
                Add
            </div>
        </div>
    </div>
)
}

export default ProductListingItem
