
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Colors from "../../utils/theme/colors"
import PrimaryButton from '../../components/button/primary';
import { MdAddShoppingCart, MdDeleteForever } from 'react-icons/md';
import useValidation from '../../utils/helper/validate';

const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]


function MarketPlaceCart(){

let navigate = useNavigate();
let validate = useValidation()

return(
<>
    <div className='inner-content-bag' >
        <div style={{width:"100%", borderRadius:'5px', textAlign:'left' }}>
                <div className="row no-margin">
                    <div className="col-md-7 no-padding-on-mobile" style={{padding:"0px 7px 0px 10px", marginBottom:"20px"}}>
                        <div className="bold h2" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.bg2, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {"\u20A6"}{validate.toLocal(2393000)} | 
                            <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>For {4} items in cart</span>
                        </div>
                    </div>
                    <div className="col-md-5 no-padding-on-mobile" style={{padding:"0px 0px 0px 7px", }}>
                       <div style={{marginTop:"-10px"}}>
                            <PrimaryButton
                                label={"Complete Checkout"} width="100%" br={'0px'}
                                Icon = {MdAddShoppingCart} callback={()=>navigate("/marketplace/checkout")}
                            />
                       </div>
                    </div>
                </div>

                <div className="no-padding-on-mobile" style={{paddingLeft:'10px', marginTop:'20px'}}>
                    <DataTable 
                        value={items_} showGridlines size="small" stripedRows rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Item" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Size" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Variant" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="price" header="Price" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="medium">{"\u20A6"}{validate.toLocal(p?.price)}</div>)
                            }}
                        ></Column>
                        <Column field="qty" header="Quantity" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="total" header="Total Amount" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="bold">{"\u20A6"}{validate.toLocal(p?.total)}</div>)
                            }}
                            headerStyle={{borderTopRightRadius:"2px"}}></Column>
                        <Column field="id" header="Remove" className="h6 regular" headerClassName={'h6 bold table-head f-w'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}} 
                            body ={(c)=>
                                <MdDeleteForever color={Colors.danger} size={20} style={{cursor:'pointer'}} />
                            }
                            ></Column>
                    </DataTable>
                </div>
        </div>
    </div>
    <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.9rem 0.5rem;  }
    .tr-body {padding:0.7rem 0.5rem; }
    .f-w {max-width:50px}
    `}</style>
</>
)
}
export default MarketPlaceCart