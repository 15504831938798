import { Link } from 'react-router-dom';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from '../../components/button/primary';
import CardNormal from '../../components/cards/cardNormal';
import Input from '../../components/input/input';
import Colors from '../../utils/theme/colors';
import AuthSidebar from './components/sidebar';
import useApi from '../../utils/helper/api';
import useValidation from '../../utils/helper/validate';
import useAlert from '../../utils/helper/toast';
import { useDispatch } from 'react-redux';
import { addAuth } from '../../redux/actions/authAction';


function LoginView(){
let [busy, setBusy] = useState(false);
let [email, setEmail] = useState({});
let [pass, setPass] = useState({});

let api = useApi();
let dispatch = useDispatch();
let validate = useValidation();
let alert = useAlert();
let navigate = useNavigate()


async function login(){
   let err = false;
   if(!email.v || email.v.length < 5 || !validate.isEmail(email.v)){
      email.e=true; email.m="Provide a valid email address"; setEmail(JSON.parse(JSON.stringify(email))); err=true;
   }
   else{ email.e=false; setEmail(JSON.parse(JSON.stringify(email))); }
   let passwordCheck = validate.isValidPassword(pass.v);
   if( passwordCheck !="valid" ){
      pass.e=true; pass.m=passwordCheck; setPass(JSON.parse(JSON.stringify(pass))); err=true;
   }
   else{ pass.e=false; setPass(JSON.parse(JSON.stringify(pass))); }

   if(err){ return; }
   let p = {email:email.v, pass:pass.v}
   setBusy(true)
   let result = await api.post('auth/login', p); //console.log(result);
   setBusy(false)
   if(result.status=="ok"){
      alert.toast(result.message, 'success')
      let authData = {token:result?.data?.token, time: (new Date()).getTime(), user:result?.data?.user   }
      dispatch(addAuth(authData));
      navigate("/dashboard")
   }
   else { alert.toast(result.message, 'error') }
   
}

return(
   <div className='row no-margin'>

      <div className='col-md-6' style={{width:"100%", minHeight:"100vh", display:'flex', justifyContent:'center', alignItems:"center"}}>
         <div className='col-md-10 no-padding'>
            <CardNormal styles={{border:"none", boxShadow:"none", backgroundColor:"none"}}>
               <div align="center" style={{margin:"15px 0px", padding:"0px 10px"}}>
                  <div className='bold h2' style={{color:Colors.primary}}><b>Welcome back</b> </div>
                  <div className='regular h5' style={{color:Colors.gray}}>Sign in with your details below</div>

                  <div style={{marginTop:"35px"}} align="left">
                     <Input
                        placeholder={"Enter email"} label="Email" bg={Colors.bgShade1} type="email"
                        value={email.v} callback={(v)=>{email.v=v; setEmail(JSON.parse(JSON.stringify(email)))}}
                        hasError={email.e} errorMessage={email.m}
                      />
                  </div>
                  <div style={{marginTop:"20px"}} align="left">
                     <Input
                        placeholder={"Enter password"} label="Password" password={true} bg={Colors.bgShade1}
                        value={pass.v} callback={(v)=>{pass.v=v; setPass(JSON.parse(JSON.stringify(pass)))}}
                        hasError={pass.e} errorMessage={pass.m} type="password"
                      />
                  </div>
                  <div align="right">
                     <Link to={"/auth/forgot-password"}>
                        <div className='h6' style={{color:Colors.primary, cursor:"pointer"}}>Forgot Password?</div>
                     </Link>
                  </div>
                  <div style={{margin:"15px 0px"}} align="left">
                     <PrimaryButton
                        label={"Signin"} busy={busy} callback={login}
                      />
                  </div>
                  <div align="center" style={{margin:"15px 0px", marginTop:"20px"}}>
                     <div className='bold h6'>Don't have an account?</div>
                     <Link to={"/auth/signup"}>
                        <div className='h6' style={{color:Colors.primary, cursor:"pointer", marginTop:"5px"}}>Create a new account here</div>
                     </Link>
                  </div>
               </div>
            </CardNormal>
         </div>
      </div>


      <div className='col-md-6 no-padding'>
         <AuthSidebar />
      </div>
   </div>
)
}
export default LoginView;