import React from 'react';
import {useSpring, animated} from 'react-spring';
import Colors from '../../../utils/theme/colors';
import { GiTakeMyMoney } from 'react-icons/gi';

import { FaCoins, FaRenren, FaStumbleupon, FaChartPie, FaPeopleArrows, FaLink} from 'react-icons/fa';



function AuthSidebar(){
return(
   <div style={{width:"100%", height:"100%", minHeight:"100vh", backgroundColor:Colors.bg2, padding:"2.5em 1em", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <div className='col-md-11' >
         <div className='bold h2' style={{marginBottom:"25px", textAlign:"center"}}>The platform to buy smart and save money.</div>
         <div className='sidebar-card' style={{backgroundColor:Colors.primary,  }}>
            <div style={{display:"flex", }}>
               <div style={{width:"50px", height:"50px", borderRadius:"25px", backgroundColor:Colors.primary1, display:'flex', justifyContent:'center', alignItems:"center", }}>
                  <FaPeopleArrows color={Colors.primary} size={22} />
               </div>
               <div style={{ marginLeft:"10px", width:"calc( 100% - 52px)"}}>
                  <div className='bold h4' style={{color:Colors.primary1, marginBottom:'3px'}}>Create or Join a Community.</div>
                  <div className='h6' style={{color:Colors.bg1}}>Create a new community for your group or join one. </div>
               </div>
            </div>
         </div>

         <div className='sidebar-card' style={{backgroundColor:Colors.primary, }}>
            <div style={{display:"flex", }}>
               <div style={{width:"50px", height:"50px", borderRadius:"25px", backgroundColor:Colors.primary1, display:'flex', justifyContent:'center', alignItems:"center", }}>
                  <FaLink color={Colors.primary} size={22} />
               </div>
               <div style={{ marginLeft:"10px", width:"calc( 100% - 52px)"}}>
                  <div className='bold h4' style={{color:Colors.primary1, marginBottom:'3px'}}>Invite members to your community.</div>
                  <div className='h6' style={{color:Colors.bg1}}>What's a community without members? Invite your peeps to join your community and start buying together. </div>
               </div>
            </div>
         </div>

         <div className='sidebar-card' style={{backgroundColor:Colors.primary1, }}>
            <div style={{display:"flex", }}>
               <div style={{width:"50px", height:"50px", borderRadius:"25px", backgroundColor:Colors.primary, display:'flex', justifyContent:'center', alignItems:"center", }}>
                  <FaRenren color={Colors.primary1} size={22} />
               </div>
               <div style={{ marginLeft:"10px", width:"calc( 100% - 52px)"}}>
                  <div className='bold h4' style={{color:Colors.primary, marginBottom:'3px'}}>Buy Together.</div>
                  <div className='h6' style={{color:Colors.black}}>Explore tens of food items, groceries and consumer products that you can buy in bulk in your city. </div>
               </div>
            </div>
         </div>

         <div className='sidebar-card' style={{backgroundColor:Colors.primary1,}}>
            <div style={{display:"flex", }}>
               <div style={{width:"50px", height:"50px", borderRadius:"25px", backgroundColor:Colors.primary, display:'flex', justifyContent:'center', alignItems:"center", }}>
                  <FaCoins color={Colors.primary1} size={22}  />
               </div>
               <div style={{ marginLeft:"10px", width:"calc( 100% - 52px)"}}>
                  <div className='bold h4' style={{color:Colors.primary, marginBottom:'3px'}}>Save Money.</div>
                  <div className='h6' style={{color:Colors.black}}> Save a lot of Money by buying in bulk together with your community. </div>
               </div>
            </div>
         </div>
      </div>
   </div>
)
}
export default AuthSidebar;