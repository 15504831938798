import { Link } from 'react-router-dom';
import React, {useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/button/primary';
import CardNormal from '../../components/cards/cardNormal';
import Input from '../../components/input/input';
import Colors from '../../utils/theme/colors';
import AuthSidebar from './components/sidebar';
import InvestorSignup from './components/investor';
import PersonalSignup from './components/personal';
import BusinessSignup from './components/business';



function SignupView(){
let [status, setStatus] = useState('personal');
let dref = useRef();
let navigate = useNavigate()

function switchScreen(x){
   dref.current.classList.add("fade-out"); 
   setTimeout(()=>setStatus(x), 500)
}

function welcomeBack(){   setStatus(null);   }

return(
   <div className='row no-margin'>
      <div className='col-md-6' style={{width:"100%", minHeight:"100vh", display:'flex', justifyContent:'center', alignItems:"center"}}>
         <div className='col-md-10 no-padding'>
            {
               status=="business" ? <BusinessSignup callback={welcomeBack} />:
               status=="personal" ? <PersonalSignup callback={welcomeBack} />:
               status=="investor" ? <InvestorSignup callback={welcomeBack} /> :
            
               <CardNormal styles={{border:"none", boxShadow:"none", backgroundColor:"none"}}>
                  <div align="center" style={{margin:"15px 0px", padding:"0px 10px", display:'none'}} ref={dref}>
                     <div className='bold h2' style={{color:Colors.primary}}><b>Create an account</b> </div>
                     <div className='regular h5' style={{color:Colors.gray}}>Select your preferred account type below to continue</div>

                  <div align="left">

                     <div className='sidebar-card sc' onClick={()=>switchScreen("investor")} style={{backgroundColor:Colors.primary1, minHeight:"50px"}}>
                        <div style={{display:"flex", alignItems:"center" }}>
                           <div style={{width:"20px", height:"20px", borderRadius:"10px", backgroundColor:Colors.black, display:'none', justifyContent:'center', alignItems:"center", }}>
                           </div>
                           <div style={{ marginLeft:"10px", width:"calc( 100% - 30px)"}}>
                              <div className='bold h4' style={{color:Colors.primary}}>Investor Account.</div>
                              <div className='h6' style={{color:Colors.black}}> Create account as an individual investor. You will have access to the marketplace to lend money but you cannot take personal loans. </div>
                           </div>
                        </div>
                     </div>
                     <div className='sidebar-card sc' onClick={()=>switchScreen("personal")} style={{backgroundColor:Colors.primary1, minHeight:"50px"}}>
                        <div style={{display:"flex", alignItems:"center" }}>
                           <div style={{width:"20px", height:"20px", borderRadius:"10px", backgroundColor:Colors.black, display:'none', justifyContent:'center', alignItems:"center", }}>
                           </div>
                           <div style={{ marginLeft:"10px", width:"calc( 100% - 30px)"}}>
                              <div className='bold h4' style={{color:Colors.primary}}>Personal Account.</div>
                              <div className='h6' style={{color:Colors.black}}> Create account as an individual borrower. You will be able to take personal loans and access BNPL and Collection Circles but you cannot invest. </div>
                           </div>
                        </div>
                     </div>
                     <div className='sidebar-card sc' onClick={()=>switchScreen("business")} style={{backgroundColor:Colors.primary1, minHeight:"50px"}}>
                        <div style={{display:"flex", alignItems:"center" }}>
                           <div style={{width:"20px", height:"20px", borderRadius:"10px", backgroundColor:Colors.black, display:'none', justifyContent:'center', alignItems:"center", }}>
                           </div>
                           <div style={{ marginLeft:"10px", width:"calc( 100% - 30px)"}}>
                              <div className='bold h4' style={{color:Colors.primary}}>Business Account.</div>
                              <div className='h6' style={{color:Colors.black}}> Create account as a business. This will enable you to access finance for your business but you cannot invest. </div>
                           </div>
                        </div>
                     </div>
                  </div>

            

                     
                     
                     <div align="center" style={{margin:"15px 0px", marginTop:"30px"}}>
                        <div className='bold h6'>Have an account?</div>
                        <Link to={"/"}>
                           <div className='h6' style={{color:Colors.primary, cursor:"pointer",  marginTop:"5px"}}>Login here</div>
                        </Link>
                     </div>
                  </div>
               </CardNormal>
         }
         </div>
      </div>


      <div className='col-md-6 no-padding'>
         <AuthSidebar />
      </div>
   </div>
)
}
export default SignupView;