import { Link } from 'react-router-dom';
import React, {useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PrimaryButton from '../../components/button/primary';
import CardNormal from '../../components/cards/cardNormal';
import Input from '../../components/input/input';
import Colors from '../../utils/theme/colors';
import useApi from '../../utils/helper/api';
import useValidation from '../../utils/helper/validate';
import useAlert from '../../utils/helper/toast'; 


function PasswordResetView(){
let [busy, setBusy] = useState(false);
let [pass, setPass] = useState({});
let [cpass, setCPass] = useState({});

let api = useApi();
let validate = useValidation();
let alert = useAlert();
let navigate =useNavigate()
let location = useLocation()


async function verify(){
   let err = false;
   let passwordCheck = validate.isValidPassword(pass.v);
   if( passwordCheck !="valid" ){
      pass.e=true; pass.m=passwordCheck; setPass(JSON.parse(JSON.stringify(pass))); err=true;
   }
   else{ pass.e=false; setPass(JSON.parse(JSON.stringify(pass))); }
   cpass.e=false; setCPass(JSON.parse(JSON.stringify(cpass)));
   if(!cpass.v || pass.v != cpass.v || cpass.v.length < 7 ){
      cpass.e=true; cpass.m="Confirm password incorrect."; setCPass(JSON.parse(JSON.stringify(cpass))); err=true;   }
             
   if(err){  return; }

   let p = {email:location?.state?.email, otp:location?.state?.otp, pass:pass.v }
   setBusy(true)
   let result = await api.post('auth/reset/reset-password', p); //console.log(result);
   setBusy(false)
   if(result.status=="ok"){
      alert.toast(result.message, 'success')
      navigate("/" )
   }
   else { alert.toast(result.message, 'error') }

}

return(
   <div className=' no-margin' style={{minHeight:"100vh"}}>

      <div style={{width:"100%", minHeight:"95vh", display:'flex', justifyContent:'center', alignItems:"center"}}>
         <div className='col-md-5 no-padding'>
            <CardNormal styles={{border:"none", boxShadow:"none", backgroundColor:"none"}}>
               <div align="center" style={{margin:"15px 0px", padding:"0px 10px"}}>
                  <div className='bold h2' style={{color:Colors.primary}}><b>Reset Password</b> </div>
                  <div className='regular h5' style={{color:Colors.gray}}>Set a new password for your account</div>

                  <div style={{marginTop:"35px"}} align="left">
                     <Input
                        placeholder={"Choose new password"} label="Create new Password" password={true} bg={Colors.bgShade1}
                        value={pass.v} callback={(v)=>{pass.v=v; setPass(JSON.parse(JSON.stringify(pass)))}}
                        hasError={pass.e} errorMessage={pass.m} type={'password'}
                      />
                  </div>

                  <div style={{marginTop:"20px"}} align="left">
                        <Input
                              placeholder={"Repeat password to confirm"} label="Confirm Password" password={true} bg={Colors.bgShade1}
                              value={cpass.v} callback={(v)=>{cpass.v=v; setCPass(JSON.parse(JSON.stringify(cpass)))}}
                              hasError={cpass.e} errorMessage={cpass.m} type={'password'}
                        />
                  </div>
                   
                  <div style={{margin:"15px 0px", marginTop:"30px"}} align="left">
                     <PrimaryButton
                        label={"  Submit  "} busy={busy} callback={verify}
                      />
                  </div>
                   
               </div>
            </CardNormal>
         </div>
      </div>


      <div style={{minHeight:"5vh", backgroundColor:Colors.primary}}>
         
      </div>
   </div>
)
}
export default PasswordResetView;