import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import PasswordResetView from '../../views/auth/reset';


function PasswordResetPage(){
return(
   <div>
      <PageBuilder PageComponent={PasswordResetView} title={"Reset Password"} />
   </div>
)
}
export default PasswordResetPage;