import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import PasswordForgotView from '../../views/auth/forgot';


function ForgotPage(){
return(
   <div>
      <PageBuilder PageComponent={PasswordForgotView} title={"Forgot Password"} />
   </div>
)
}
export default ForgotPage;