export const AUTH_DO ="ADD_AUTH";
export const AUTH_REMOVE = "REMOVE_AUTH";
export const REG_NEW = "NEW_REG";
export const AUTH_MODAL_SET = "ACTIVATE_AUTH_MODAL";
export const AUTH_MODAL_REMOVE = "REMOVE_AUTH_MODAL";

export const RESET_STORE = "RESET STORE";

/*Dashboard*/

export const DASH_START_LOADING = "DASH START LOADING";
export const DASH_STOP_LOADING ="DASH STOP LOADING";
export const DASH_CHART = "DASH CHART"; 
export const DASH_RECENT = "DASH RECENT"; 
export const DASH_ACCOUNT ="DASH ACCOUNT";


/*Account*/

export const ACCOUNT_START = "ACCOUNT START"; // for /accounts. Fetch connected accounts and their recent transactions
export const ACCOUNT_SUB_START = "ACCOUNT SUB START";
export const ACCOUNT_START_LOADING = "ACCOUNT START LOADING";
export const ACCOUNT_STOP_LOADING ="ACCOUNT STOP LOADING";
export const ACCOUNT_SMS ="ACCOUNT SMS";
export const ACCOUNT_PAY_TRXS ="ACCOUNT PAY TRXS";

/*Credit*/

export const CREDIT_STATUS = "CREDIT_STATUS"; 
export const CREDIT_START_LOADING = "CREDIT_START_LOADING";
export const CREDIT_STOP_LOADING ="CREDIT_STOP_LOADING";

/*Team*/

export const TEAM_LIST = "TEAM_LIST"; 
export const TEAM_START_LOADING = "TEAM_START_LOADING";
export const TEAM_STOP_LOADING ="TEAM_STOP_LOADING";

/*Sale*/
export const SALE_START_LOADING = "SALE_START_LOADING";
export const SALE_STOP_LOADING ="SALE_STOP_LOADING";
export const SALE_ITEM_CATS ="SALE_ITEM_CATS";
export const SALE_ITEM_LIST ="SALE_ITEM_LIST";
export const SALE_ITEM_GET ="SALE_ITEM_GET";
export const SALE_LINKS ="SALE_LINKS";
export const SALE_SALES ="SALE_SALES";

/*Settings*/

export const SET_GET = "SET_GET"; 
export const SET_START_LOADING = "SET_START_LOADING";
export const SET_STOP_LOADING ="SET_STOP_LOADING";

/*COMPLIANCE*/

export const COMPLY_GET = "COMPLY_GET"; 
export const COMPLY_START_LOADING = "COMPLY_START_LOADING";
export const COMPLY_STOP_LOADING ="COMPLY_STOP_LOADING";

/*Transfer*/

export const TRANSFER_BANKS = "TRANSFER_BANKS";
export const TRANSFER_GET = "TRANSFER_GET"; 
export const TRANSFER_START_LOADING = "TRANSFER_START_LOADING";
export const TRANSFER_STOP_LOADING ="TRANSFER_STOP_LOADING";



