
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Colors from "../../../utils/theme/colors";


const menus = ['activities', 'orders', 'contributions', 'members', 'about']

function CommunityMenu(){

let params = useParams();
let path = window.location.pathname;

function testActive(m){
    let paths_ = path.split(params.cid);
    if(m=='activities'){
        return paths_[1] == '' || paths_[1] =='/'
    }
    return path.indexOf(m) >=0;
}

return(
    <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left', overflow:'hidden' }}>
        <div className='bold h5' style={{color:Colors.black, textDecoration:"underline", padding:"0px 10px 12px 0px"}}>Community Name:</div>

        <div style={{marginTop:'5px', paddingBottom:"0px"}}>
            {
                menus.map((m, i)=>
                    <Link
                        key={i}
                        to = { m=='activities' ? "/dashboard/community/"+params.cid : "/dashboard/community/"+params.cid +"/"+m  }
                        className={ testActive(m) ? "medium h5 sub-menu-item-active" : "medium h5 sub-menu-item" }
                        style={{
                            display:'block', textAlign:'left', alignItems:'center', display:'flex', padding:"10px 10px",
                            textTransform:'capitalize'
                        }}
                    >
                        {m}
                    </Link>
                )
            }
        </div>
    </div>
)
}
export default CommunityMenu;


