import React, {useEffect, useState, useRef, useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import * as faceapi from 'face-api.js'
//import Preview from './preview';

import "./Camera.css"
import Colors from "../../../utils/theme/colors";
import PrimaryButton from "../../../components/button/primary";

function IdentitySelfie({callback}){

let [isFlashing, setIsFlashing] = useState(false)
let [cameraStatus, setCameraStatus] = useState('opened')
let [activeTab, setActiveTab] = useState('')
let [screenshot, setScreenshot] = useState(null)
let [message, setFacenetMessage] = useState('Place your face in the oval below')
let [outline, setOutline] = useState(Colors.light)

let webcamRef = useRef();
const containerRef = useRef()
const canvasRef = useRef()
const detection = useRef()


//console.log(screenshot);

useEffect(() => {
        return () => {  clearInterval(detection.current)  }
    },[cameraStatus])

const takeScreenshot = () => {
    if(webcamRef.current !=null){
        setScreenshot(webcamRef?.current?.getScreenshot())
    }
    setIsFlashing(true)
    setCameraStatus('preview')
}

const handleCameraError = (e) => { //console.log(e)
    setCameraStatus('closed')
    //dispatch(setCameraStatus('closed'))
    const err = {}
    err[activeTab] = {serverErr: 'There was a problem accessing the WEBCAM. Grant permission and reload the page.'}
}


const handleStreamVideo = async (e) => { //console.log(e); 

    await faceapi.nets.tinyFaceDetector.loadFromUri('/facenet/models/tiny_face_detector')
    let counter = 0;
    detection.current = setInterval( async () => {
        if (counter <= 40) {
            if(webcamRef.current==null) return;
            const faces = await faceapi.detectAllFaces(webcamRef?.current?.video, new faceapi.TinyFaceDetectorOptions())
            if (faces.length === 1 && faces[0].score > 0.5) { takeScreenshot()
                counter++
                setOutline(Colors.primary)
                setFacenetMessage('Stand still for ' + Math.round(4 - (counter / 10)) + ' seconds.')
            } else {
                counter = 0
                setOutline(Colors.danger); //'#f00000'
                setFacenetMessage('Place your face in the oval.')
            }
        } else {
            takeScreenshot()
        }
    }, 100)
}


async function submit(){
    callback(screenshot)
}

return(
    <div style={{width:'100%', minHeight:'300px', display:'flex', justifyContent:'center', alignItems:'center' }}>
        <div style={{width:"100%", height:'400px'}}>
        {(cameraStatus === 'opened') &&
            <div style={{width:"100%", height:'400px', position:'relative', overflow:'hidden'}}>
                <Webcam
                    className="camera-video"
                    id="webcam"
                    ref={webcamRef}
                    screenshotFormat='image/jpeg'
                    screenshotQuality= {1}
                    width={'100%'}
                    height={'100%'}
                    mirrored={true}
                    videoConstraints={{facingMode: 'user'}}
                    onUserMedia={(e) => handleStreamVideo(e)}
                    onUserMediaError={handleCameraError}
                />
                <canvas id="camera-canvas" ref={canvasRef}>Your browser does not support the HTML canvas tag.</canvas>
                <div className="camera-face-overlay" style={{borderColor: outline}}>
                    <div className="camera-face-message">{message}</div>
                </div>
            </div>
        }

        {(cameraStatus === 'preview') &&
            <div>
                <div>
                    <img src={screenshot} style={{width:'100%', borderRadius:'5px', }} />
                </div>
                <br/>
                <div className="row no-margin">
                    <div className="col-md-6" style={{paddingLeft:"0px"}}>
                        <PrimaryButton size="small" width="100%"
                            label="Retake selfie" callback={()=>setCameraStatus("opened")}
                         />
                    </div>
                    <div className="col-md-6" style={{paddingRight:"0px"}}>
                        <PrimaryButton size="small" width="100%"
                            label="Submit selfie" callback={submit}
                         />
                    </div>
                </div>
            </div>
        }
           
            <div className="camera-flash"
                style = {{ animation: isFlashing && 'flashAnimation 850ms ease-out', }}
                onAnimationEnd={() => setIsFlashing(false)} /></div>
    </div>
)
}
export default IdentitySelfie;