import React from 'react';
import BottomNavigation from '../../components/header/bottomnav';
import AppHeader from '../../components/header/header';
import TopNavigation from '../../components/header/topnav';
import Colors from '../theme/colors';




function ParentNavigation({children}){
return(
   <div >
      <div>
         <AppHeader />
      </div>
      <center>
         <div className='content-body-bag col-md-9'>
            <TopNavigation />
            <div>
               {children}
            </div>
         </div>
      </center>
      <div>
         <BottomNavigation />
      </div>
   </div>
)
}
export default ParentNavigation;