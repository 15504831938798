import React from 'react';
import AppDashboard from '../../views/dashboard/dash';
import PageBuilder from '../../utils/helper/pagebuilder';


function DashboardPage(){
return(
   <div>
      <PageBuilder PageComponent={AppDashboard} title={"Dashboard"} />
   </div>
)
}
export default DashboardPage;