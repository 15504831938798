
import React, {useEffect, useState} from "react";
import { createPortal } from 'react-dom';
import { MdAdd, MdNaturePeople, MdOutlineShoppingCart, MdRemove } from "react-icons/md";
import { Link } from "react-router-dom";
import ProductCategory from "./components/prod-cat";

import Colors from "../../utils/theme/colors";
import ProductListingItem from "./components/prod-item";
import MainSideContentModal from "../../components/modal/slidemodal";
import useValidation from "../../utils/helper/validate";

function MarketPlace(){
let [open, setOpen] = useState(false)
let [busy, setBusy] = useState(false);
let [prod, setProd] = useState(null);
let [size, setSize] = useState(null)
let [variant, setVariant] = useState(null)
let [qty, setQty] = useState(1)

let sizes = ['small', 'normal', 'large']

let validate = useValidation()

function QtyAdd(){
    setQty ( qty + 1)
}
function QtyReduce(){
    setQty ( qty > 1 ? qty -1 : 1 )
}

const products_ = [
   {photo:null, name:"Foreign rice (long)", id:"2424204", amount:"42424", starter_photo:null, member_count:12},
   {photo:null, name:"Beans (oloyin)", id:"2424204", amount:"353532", starter_photo:null, member_count:12},
   {photo:null, name:"Beans (drum)", id:"2424204", amount:"4131", starter_photo:null, member_count:12},
   {photo:null, name:"Beans (white)", id:"2424204", amount:"5000", starter_photo:null, member_count:12},
   {photo:null, name:"Semovita", id:"2424204", amount:"6500", starter_photo:null, member_count:12},
   {photo:null, name:"Palm Oil", id:"2424204", amount:"6500", starter_photo:null, member_count:12},
   {photo:null, name:"Mamador Groundnut oil", id:"242204", amount:"6500", starter_photo:null, member_count:12},
]

async function addToCart(){}

return(
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"20px"}} >
            <ProductCategory />
        </div>
        <div className="col-md-9 no-padding-on-mobile" style={{padding:"0px 0px 0px 20px", textAlign:'left'}}>
            <div className='row no-margin' style={{marginTop:"20px", width:'100%', display:'flex', alignItems:'flex-start', justifyContent:'flex-start', flexWrap:'wrap', clear:'both'}}>
                {
                    products_.map((p, i)=>
                    <div key={i} className="col-md-4 no-padding-on-mobile" 
                        style={{paddingRight:i%3==2 ? "0px": "15px", paddingLeft:"0px", marginBottom:'35px'}}>
                        <ProductListingItem prod = {p} callback={()=>{ setProd(p); setOpen(true) }} /> 
                    </div>
                    )
                }
            </div>
        </div>
    </div>

    <MainSideContentModal open={open} close={()=>setOpen(false)}
        title={prod?.name} buttonTitile={"Add To Cart"} useButton buttonCallback={addToCart}
        >
        <div style={{padding:"15px 12px", color:Colors.black}}>
            <div style={{height:'140px', position:'relative', backgroundColor:Colors.bg2, display:'flex', alignItems:'center', justifyContent:'center' }}>
                {  prod?.photo? 
                    <img src={prod?.photo} style={{width:"100%"}} className="img-regular" />
                    :
                    <div style={{width:"50px", height:'50px', borderRadius:"50%", backgroundColor:Colors.primary, alignItems:'center', justifyContent:'center', display:'flex' }}>
                        <MdNaturePeople color={Colors.bgLight} size={22} />
                    </div>
                }
            </div>
            <div className="bold h5" style={{textDecoration:'underline', margin:'15px 0px'}}>Size:</div>
            <div style={{width:'100%', display:'flex', alignItems:'flex-start', flexWrap:'wrap'}}>
                {
                    sizes.map((s, i)=>
                        <div className="h6 primary-color" key={i} 
                            style={{padding:"6px 10px", cursor:'pointer', margin:"5px 10px 5px 0px", backgroundColor:Colors.bg}}>
                                {s}
                            </div>
                    )
                }
            </div>

            <div className="bold h5" style={{textDecoration:'underline', margin:'15px 0px'}}>Variant:</div>
            <div style={{width:'100%', display:'flex', alignItems:'flex-start', flexWrap:'wrap'}}></div>

            <div className="bold h5" style={{display:'flex', alignItems:'center', justifyContent:'space-between', margin:'15px 0px', flexDirection:'row'}}>
                <div>Quantity:</div>
                <div style={{display:'flex', flexDirection:'row', padding:'5px 12px'}}>
                    <div className="flex-center" style={{padding:"10px"}} >
                        <MdRemove className="primary-color" color={Colors.black} size={24} onClick={QtyReduce} style={{cursor:'pointer'}} />
                    </div>
                    <div className="bold h4 no-margin flex-center" style={{padding:"2px 15px", border:"1px solid "+Colors.primary, backgroundColor:Colors.bg}}>
                        {qty}
                    </div>
                    <div className="flex-center" style={{padding:"10px 20px"}} >
                        <MdAdd className="primary-color" color={Colors.black} size={24} onClick={QtyAdd} style={{cursor:'pointer'}} />
                    </div>
                </div>
            </div>

            <div className="bold h5" style={{display:'flex', alignItems:'center', justifyContent:'space-between', margin:'15px 0px', flexDirection:'row'}}>
                <div>Price:</div>
                <div>{"\u20A6"}{validate.toLocal(3000)}</div>
            </div>

            <div className="bold h5" style={{display:'flex', alignItems:'center', justifyContent:'space-between', margin:'30px 0px', flexDirection:'row', borderTop:"1px solid "+Colors.bgShade1, padding:"15px 0px"}}>
                <div>Total:</div>
                <div>{"\u20A6"}{validate.toLocal(3000)}</div>
            </div>
        </div>
    </MainSideContentModal>
    { createPortal(
        <div className="flex-center cart-call" style={{position:'fixed', width:'100%' }}>
            <Link to="/marketplace/cart">
                <div style={{display:'flex', flexDirection:'row', overflow:'hidden', borderRadius:'0px', backgroundColor:Colors.bg2, border:"1px solid "+Colors.primary}}>
                    <div className="flex-center" style={{backgroundColor:Colors.primary1, padding:'10px', minWidth:'50px'}}>
                        <MdOutlineShoppingCart color={Colors.primary} />
                    </div>
                    <div className="h6" style={{padding:'10px 15px'}}>0 items added</div>
                </div>
            </Link>
        </div>
    , document.body)}
</div>
)
}
export default MarketPlace;