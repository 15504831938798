import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminPersonalAccount from '../../views/admin/account';


function AdminPersonalAccountPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminPersonalAccount}  />
   </div>
)
}
export default AdminPersonalAccountPage;