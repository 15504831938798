
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import OrderMenu from "./components/ord-menu";
import Colors from "../../utils/theme/colors";
import useValidation from "../../utils/helper/validate";
import PrimaryButton from "../../components/button/primary";


const activity_types = ['recived', 'preparing order', 'on route for delivery', 'order delivered'];
const status_list = [
    {label:"Order Recieved", value:'received'},
    {label:"Preparing Order", value:'received'},
    {label:"Order Enroute for delivery", value:'enroute'},
    {label:"Order Delivered", value:'delivered'},
];
const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]


function AdminVendorDetailsView(){
let [open, setOpen] = useState(false)
let params = useParams();
let validate = useValidation();

return(
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-4 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"30px"}} >
            <div style={{width:"100%",textAlign:'left', color:Colors.black, }}>
                <div className="bold h4" style={{width:"100%", height:"50px", borderRadius:'5px', backgroundColor:Colors.bg2, padding:"12px", display:'flex', alignItems:'center', color:Colors.black, }}>
                    Vendor Name | 
                            <span className="h7 regular" style={{color:Colors.black, marginLeft:'8px'}}>Ibadan</span>
                </div>
                <div className='bold h6' style={{ textDecoration:"underline",margin:"20px 0px"}}>09024001203 | jaiye@gmail.com</div>
                <div className="h7">Detailed address comes here. It really must be detailed in deed</div>
                <hr style={{backgroundColor:Colors.bgShade1}} />
                <div className="h7">Additional notes comes here</div>
            </div>
        </div>
        <div className="col-md-8 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px"}}>
            <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left' }}>
                <div className='bold h2 no-padding-on-mobile' style={{color:Colors.black, textDecoration:"underline", marginBottom:'20px', padding:"0px 10px 10px 10px"}}>
                    Product List: 
                </div>
                
                <div className="no-padding-on-mobile" style={{paddingLeft:'10px'}}>
                    <DataTable 
                        value={items_} showGridlines size="small" stripedRows rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular"headerClassName={'h6 bold table-head'}
                            headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Product" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="price" header="Cost Price" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="medium">{"\u20A6"}{validate.toLocal(p?.price)}</div>)
                            }}
                        ></Column>
                        <Column field="qty" header="Quantity" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head f-w'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}} 
                            body ={(c)=>
                                <Link to="/ad-management/products/0242422" style={{color:Colors.primary}}>View</Link>
                            }
                            ></Column>
                    </DataTable>
                </div>

                
                
            </div>
        </div>
    </div>
    <Dialog visible={open} closeOnEscape  onHide={()=>setOpen(false)}>
         <div >
            <div className='bold h4' style={{color:Colors.black}}>Do you want to mark this order as completely fulfiled?</div>
            <div>Ensure the order has been delivered and confirmed before marking this as closed and complete</div>
            <div style={{marginTop:"30px"}}>
               <PrimaryButton label={"No, Cancel"} callback={()=>setOpen(false)}
                  br="0px" bg={Colors.bgShade1} color={Colors.black}  width="100%" border={"1px solid gray"}
                />
            </div>

            <div style={{marginTop:"20px"}}>
               <PrimaryButton
                  label={"Yes, Order has been fulfiled!"} callback={()=>0} width="100%" br="0px"
                />
            </div>
         </div>
      </Dialog>
    <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.7rem 0.5rem;  }
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>
</div>
)
}
export default AdminVendorDetailsView