import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import PersonalAccount from '../../views/account/account';


function AccountPage(){
return(
   <div>
      <PageBuilder PageComponent={PersonalAccount} title={"Dashboard"} />
   </div>
)
}
export default AccountPage;