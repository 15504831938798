import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import Colors from '../../utils/theme/colors';
import {FaPowerOff, FaUser} from 'react-icons/fa';
import PrimaryButton from '../button/primary';



function AdminHeader(){
let [open, setOpen] = useState(false)

async function signout(){}

return(
   <div className='app-header' style={{borderBottomColor:Colors.bgShade1, backgroundColor:Colors.bgLight}}>
      <div className="bold h3">
         <span style={{color:Colors.primary}}>Group</span><span style={{color:Colors.black}}>Buy Admin</span>
      </div>
      <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center" }}>
         <Link to={"/ad-management/dashboard/account"} style={{display:"none"}}>
            <div className='power-box add-shadowo'>
               <FaUser color={Colors.primary}  />
            </div>
         </Link>
         <div className='power-box ' onClick={()=>setOpen(true)}>
            <FaPowerOff color={Colors.danger} />
         </div>
      </div>



      <Dialog visible={open} closeOnEscape  onHide={()=>setOpen(false)}>
         <div >
            <div className='bold h4' style={{color:Colors.black}}>Do you want to signout of <span style={{color:Colors.primary}}>Group</span><span style={{color:Colors.black}}>Buy Admin</span>?</div>

            <div style={{marginTop:"30px"}}>
               <PrimaryButton label={"No, Cancel"} callback={()=>setOpen(false)}
                  br="0px" bg={Colors.bgShade1} color={Colors.black}  width="100%" border={"1px solid gray"}
                />
            </div>

            <div style={{marginTop:"20px"}}>
               <PrimaryButton
                  label={"Yes, Sign me out!"} callback={signout} bg={Colors.danger} width="100%" br="0px"
                />
            </div>
         </div>
      </Dialog>
   </div>
)
}
export default AdminHeader;