import PrimaryButton from "../../../components/button/primary";
import Colors from "../../../utils/theme/colors";

function IdentityStart({name, callback}){

return(
    <div style={{width:'100%', minHeight:'300px', }}>
        <div >
            <div className="bold h4" style={{ textDecoration:"underline" }}>
                Verify your account:
            </div>
            <br />
            <div style={{ fontSize:"14px", textAlign:"left", marginBottom:'15px'}}>
                Dear {name || "Carlos"}, your account verification process will require:
                <ol style={{marginTop:'10px'}}>
                    <li>Selfie capture</li>
                    <li>BVN Verification</li>
                </ol>
            </div>

            <div > 
                <PrimaryButton
                    label="Start Verification" callback={callback ? callback : ()=>0}
                    size="small" br={"0px"}
                 />
            </div>
        </div>
    </div>
)
}
export default IdentityStart;