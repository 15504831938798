import React, {useEffect, useState} from "react";
import { Tooltip } from 'react-tooltip'
import { FiCamera } from "react-icons/fi";
import Input from "../../../components/input/input";
import Colors from "../../../utils/theme/colors"
import useAlert from "../../../utils/helper/toast";

function AccountProfile({d, photo}){
let [image, setImage] = useState(null);
let alert = useAlert()


const handleImage=async(e)=>{ 
    if(e.target.files[0]==undefined){ return; }
    let name =e.target.files[0].name.split('.');
    let ext=name[name.length - 1]; 
    let size = Math.round(e.target.files[0].size/1024); 
    if(size > 500){ alert.toast("Image size limit exceeded. Maximum size allowed is 500KB", 'error');  return; }
    const reader = new FileReader(); 
      reader.readAsDataURL(e.target.files[0]); 
      reader.onload=()=>{ 
        var img = new Image(); 
        img.onload=()=>{ 
          let r= { ext:ext, base64:reader.result, }; image= r; setImage(JSON.parse(JSON.stringify(image)))
        }
        img.src=reader.result;
      };
 }


return(
    <div className="fade-in" style={{opacity:0}}>
        <div>
            <input id="itemimage" style={{display:'none'}} type="file" onChange={handleImage} />
            <div style={{textAlign:'left', marginBottom:'20px'}}>
                <div 
                onClick={()=>document.getElementById("itemimage").click()}
                data-tooltip-id="tip" data-tooltip-content={"Tap to change profile picture"}
                data-tip="Tap to change profile picture" style={{width:"100px", height:"100px", borderRadius:"0%", cursor:"pointer", display:"flex", alignItems:'center', justifyContent:'center', backgroundColor:Colors.bgShade1}}>
                {image !==null ?
                    <img className="img-regular" src={image.base64} style={{height:"100px", width:"100px", borderRadius:"0%"}} />
                : photo && photo !="" ?
                    <img src={photo} style={{height:"100px", width:"100px", borderRadius:"0%"}} />
                : 
                    <FiCamera size={22} color={Colors.primary} />
                }
                </div>
                { image !=null && 
                <div style={{marginTop:"5px", fontSize:"14px", textDecoration:'underline', color:Colors.primary}}>
                    <a href="#" style={{color:Colors.primary}}>Save new picture</a>
                </div>
                }
            </div>
        </div>
        <div className="row no-margin">
                <div className='col-md-6 no-padding-on-mobile' style={{padding:"0px 10px 0px 0px" }}>
                    <Input
                        label_fw={"normal"} label="Firstname" bg={Colors.bgShade1} disabled
                        value={'Carlos'} callback={(v)=>0}
                    />
                </div>
                <div className='col-md-6 no-padding-on-mobile' style={{padding:"0px 0px 0px 10px", }}>
                    <Input
                        label_fw={"normal"} label="Lastname" bg={Colors.bgShade1}   disabled
                        value={'Don'} callback={(v)=>0}
                    />
                </div>

                <div className='col-md-6 no-padding-on-mobile' style={{padding:"0px 10px 0px 0px" }}>
                    <Input
                        label_fw={"normal"} label="Email" bg={Colors.bgShade1} disabled
                        value={'carlos@gmail.com'} callback={(v)=>0}
                    />
                </div>
                <div className='col-md-6 no-padding-on-mobile' style={{padding:"0px 0px 0px 10px", }}>
                    <Input
                        label_fw={"normal"} label="Phone number" bg={Colors.bgShade1} type="number" disabled
                        value={"090242489012"} callback={(v)=>0}
                    />
                </div>

        </div>
        <Tooltip id="tip" />
    </div>
)
}
export default AccountProfile;