import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {activateModal} from '../../redux/actions/am';



function useGuard(){
   /*Our job is to check that there is an active session
   otherwise, we popup a login modal prompting user to login again 
   If the user has no previous session, we redirect to login page
   */
   let navigate = useNavigate()
   const auth = useSelector((state)=>state.Auth.Auth);
   let dispatch = useDispatch();
   if(auth==null){
      //This means there is no previous authentication. So we redirect to login;
      //navigate("/")
      return false;
   }
   var now = (new Date()).getTime(); 
   if(auth.time < (now - (60*60*2*1000) ) ){
      //Time is in milliseconds. Let's keep session for 2 hours (session expires after 2 hours of in activity)
      //prompt user to signin again
      //dispatch(activateModal())
      //navigate("/")
      return false;
   }
   else {return true;}
   
   return false;
}
export default useGuard;