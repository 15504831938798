import React from 'react';
import { Link } from 'react-router-dom';
import Colors from '../../utils/theme/colors';


const menus = ["home", 'marketplace', 'account', ]

function BottomNavigation(){

let path = window.location.pathname;

return(
<div className='show-on-mobile'>
   <div className='bottom-nav' style={{backgroundColor:Colors.bg2}}>
      {
        menus.map((m, i)=>
            <Link
                key={i} className="nav-menu-item bold h4"
                to={m=='home' ? '/dashboard' :"/"+ m}
                style={{backgroundColor: path.indexOf(m=='home' ? 'dashboard' :m) >=0 ? Colors.primary:null,
                    color:  path.indexOf(m=='home' ? 'dashboard' :m) >=0 ? Colors.bgLight:Colors.black,
                }}
            >
                {m}
            </Link>
        )
     }
   </div>
</div>
)
}
export default BottomNavigation;