import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import CommunityContributions from '../../views/community/contributions';


function CommunityContributionsPage(){
return(
   <div>
      <PageBuilder PageComponent={CommunityContributions} title={"Community"} />
   </div>
)
}
export default CommunityContributionsPage;