import React from 'react';
import { Link } from 'react-router-dom';
import Colors from '../../utils/theme/colors';


const menus = ["home", 'orders', 'vendors', 'products', 'more' ]


function AdminTopNavigation(){

let path = window.location.pathname;
const base_ = "/ad-management"

return(
   <div className='top-nav hide-on-mobile' style={{backgroundColor:Colors.bg}}>
    {
        menus.map((m, i)=>
            <Link
                key={i} //className="nav-menu-item bold h4"
                to={m=='home' ? base_+ '/dashboard' :  base_+"/"+ m} 
                className = {path.indexOf(m=='home' ? 'dashboard' :m) >=0 ? "nav-menu-item bold h4":"top-nav-item nav-menu-item bold h4" }
                style={{backgroundColor: path.indexOf(m=='home' ? 'dashboard' :m) >=0 ? Colors.primary:null,
                    color:  path.indexOf(m=='home' ? 'dashboard' :m) >=0 ? Colors.bgLight:Colors.black,
                }}
            >
                {m}
            </Link>
        )
    }
   </div>
)
}
export default AdminTopNavigation;