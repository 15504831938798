import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import PasswordRecoveryOTPView from '../../views/auth/otp';


function PasswordOTPPage(){
return(
   <div>
      <PageBuilder PageComponent={PasswordRecoveryOTPView} title={"Recovery OTP"} />
   </div>
)
}
export default PasswordOTPPage;