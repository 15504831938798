import React, {useState} from "react";

import PrimaryButton from "../../../components/button/primary";
import Input from "../../../components/input/input";


function IdentityBVN({callback}){
let [phone, setPhone] = useState({})
let [dob, setDOB] = useState({})
let [bvn, setBVN] = useState({});


async function Submit(){
    callback()
}

return(
    <div style={{width:'100%', minHeight:'300px', display:'flex', justifyContent:'center', alignItems:'center' }}>
        <div style={{padding:"0px 10px"}}>
            <div className="bold h4" style={{ textDecoration:"underline" }}>
                BVN Verification
            </div>
            <br />
            <div>
                <div className="row no-margin">
                    <div className="col-md-6" style={{paddingLeft:"0px"}}>
                        <Input
                            label="Phone"
                            placeholder="Enter the phone number on your BVN"
                            type="number" 
                            callback={(v)=>{phone.v=v; setPhone(JSON.parse(JSON.stringify(phone))) }}
                            value={phone.v} hasError={phone.e} errorMessage={phone.em}
                            hastip={true}  tipMessage="Enter the phone number on your BVN"
                        />
                    </div>
                    <div className="col-md-6" style={{paddingRight:"0px"}}>
                        <Input
                            label="Date of Birth"
                            placeholder="Select date of birth"
                            type="date"  
                            callback={(v)=>{dob.v=v; setDOB(JSON.parse(JSON.stringify(dob))) }}
                            value={dob.v} hasError={dob.e} errorMessage={dob.em}
                            hastip={true}  tipMessage="Select your Date of Birth"
                        />
                    </div>

                    <div style={{width:"100%", marginTop:"10px"}}>
                        <Input
                            label="BVN"
                            placeholder="Enter your BVN"
                            type="number" 
                            callback={(v)=>{bvn.v=v; setBVN(JSON.parse(JSON.stringify(bvn))) }}
                            value={bvn.v} hasError={bvn.e} errorMessage={bvn.em}
                        />
                    </div>
                </div>
            </div>
            
            <div > <br /> 
                <PrimaryButton
                    label="Verify BVN" callback={callback ? callback : ()=>0}
                   br="0px"
                 />
            </div>
        </div>
    </div>
)
}
export default IdentityBVN;