import React, {useEffect} from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import LoginView from '../../views/auth/login'

function LoginPage(){

return(
   <div>
      <PageBuilder PageComponent={LoginView} title={"Login"} />
   </div>
)
}
export default LoginPage;