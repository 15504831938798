import React, {useState, useEffect} from 'react';

import useColors from '../theme/useColor';
import AuthenticationManager from './authmanager';
import ParentNavigation from './parentnav';


function PageBuilder({PageComponent, title, pageProps, Access, Deny}){
let [auth, setAuth] = useState(null);
let colors = useColors();
let headerless = [ "auth/signup", "auth/otp", "auth/forgot-password", "auth/password-otp", "auth/verify-email", "auth/reset-password"];


useEffect(()=>{
   let path = window.location.pathname;
   setAuth( path=="/" ? false : !headerless.includes(path.replace('/', '')))
}, []);

return(
<>
    

   <div style={{width:"100%", minHeight:"100vh", backgroundColor:colors.bg1, margin:"0px", padding:"0px"}}>
      {
         auth==null ? null :
         auth == false ? <PageComponent {...pageProps} />:
         auth == true ? 
            <ParentNavigation >
               <AuthenticationManager allow = {Access} deny = {Deny} >
                  <PageComponent {...pageProps} />
               </AuthenticationManager>
            </ParentNavigation>
         :null
      }
   </div>

</>
)
}
export default PageBuilder;