import React, {useEffect} from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminLoginView from '../../views/auth/admin/login'

function AdminLoginPage(){

return(
   <div>
      <AdminPageBuilder PageComponent={AdminLoginView} title={"Login"} />
   </div>
)
}
export default AdminLoginPage;