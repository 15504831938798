import React, {FC, useState} from 'react';
import { FiEye, FiEyeOff, FiInfo } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip'

import ThemeColors from '../../utils/theme/colors';

/*type Size = "small" | "medium" | "big";
type TipType = "dark" | "light" | "success" | "warning" | "error" | "info"

interface IInput{
   placeholder:string;
   errorMessage?:string;
   hasError?:boolean;
   required?:boolean;
   hastip?:boolean;
   callback:Function;
   value?:string;
   color?:string;
   size?:Size;
   bg?:string;
   label?:string;
   tipMessage?:string;
   tipType?:TipType;
   limit?:number
}
*/
function TextArea({  
      size, placeholder, value, callback, limit, color,
      bg, label, required,
      hasError, errorMessage, hastip, tipMessage, tipType,
   }){ 
const handleUpdate=(val)=>{ 
   if(limit && limit >= val.length) { callback(val); }
}
   return(
   <div style={{width:'100%', marginBottom:'10px'}}>
      <div style={{width:'100%', marginBottom:'3px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
         <div>
            {required?<span className="required">*</span>:null}
            <span style={{fontSize:'13px', fontWeight:600,}}>{label}</span>
         </div>
         {hastip?
         <div data-tooltip-id="tip" data-tooltip-content={tipMessage} style={{width:'24px', height:'24px', borderRadius:'12px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:ThemeColors.bgShade1}}>
            <FiInfo color={ThemeColors.black} />
         </div>
         :null}
      </div>
      <div className={'input'} style={{
         width:'100%',
         flexDirection:'row',
         alignItems:'center',
      }}>
         <textarea className="input-width"
            placeholder={placeholder}
            value={value}
            cols={3}
            onChange={(e)=>handleUpdate(e.target.value)}
            style={{
               backgroundColor:  bg || ThemeColors.light,
               borderWidth:'1px',
               borderStyle:'solid',  borderRadius:'5px',
               borderColor: hasError ? "red": ThemeColors.bgShade1,
               color: color || ThemeColors.black,
               padding:!size ? "10px" : size=="small"? "8px" : size=="medium" ? "10px" : "13px",
               fontSize:!size ? "16px" : size=="small"? "14px" : size=="medium" ? "16px" : "18px",
               width:'100%'
               }} ></textarea>
         {limit ?
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'5px'}}>
               <span className='h7' style={{
                  color:value !=null && value.length < limit ? ThemeColors.black:"red",
                  fontWeight:500,
               }}
               >{value==null? 0: value.length}/{limit}</span>
            </div>
         :null}
      </div>
      {hasError?
      <div>
         <span style={{fontSize:'12px', color:"red", marginTop:'0px'}}>
            {errorMessage}
         </span>
      </div>
      :null}
      <Tooltip id = {  "tip" } />
   </div>
)
}
export default TextArea;