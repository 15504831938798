
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Chips } from 'primereact/chips';
import Colors from "../../utils/theme/colors";
import useValidation from "../../utils/helper/validate";
import PrimaryButton from "../../components/button/primary";
import ProductBasicUpdate from "./components/prod-basic";
import Input from "../../components/input/input";
import Selector from "../../components/input/select";


const activity_types = ['recived', 'preparing order', 'on route for delivery', 'order delivered'];
const status_list = [
    {label:"Order Recieved", value:'received'},
    {label:"Preparing Order", value:'received'},
    {label:"Order Enroute for delivery", value:'enroute'},
    {label:"Order Delivered", value:'delivered'},
];
const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]


function AdminProductDetailsView(){
let [open, setOpen] = useState(false)
let [sizes, setSizes] = useState(null)
let params = useParams();
let validate = useValidation();


return(
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-5 no-padding-on-mobile" style={{padding:"0px 20px 0px 0px", marginBottom:"30px"}} >
            <ProductBasicUpdate />
        </div>
        <div className="col-md-7 no-padding-on-mobile" style={{padding:"0px 0px 0px 20px"}}>
            <div style={{width:"100%", color:Colors.black, textAlign:'left' }}>
                <div className="bold h5" style={{width:"100%", height:"40px", backgroundColor:Colors.bg2, padding:"12px", display:'flex', alignItems:'center',  }}>
                    Product Sizes & Prices: 
                </div>
                <div>
                    <div className="h7" style={{margin:'15px 0px'}}>Type the supported sizes for this product below along with the corresponding price. Hyphenate the size and the price. For example - "10KG - 10000". Press "Enter" to register a size</div>
                    <div>
                        <Chips value={sizes} onChange={(e) => setSizes(e.value)} />
                    </div>
                    <div style={{marginBottom:"15px", marginTop:"-10px"}}>
                        <PrimaryButton label={"Update Sizes"} size="small" callback={()=>0} br="0px" />
                    </div>
                </div>
                <br/>
                <div className="bold h5" style={{width:"100%", height:"40px", backgroundColor:Colors.bg2, padding:"12px", display:'flex', alignItems:'center',  }}>
                    Product Variations: 
                </div>
                <div>
                    <div className="h7" style={{margin:'15px 0px'}}>If this product has variation, type the supported options below. Press "Enter" to register an option</div>
                    <div>
                        <Input label_fw={'normal'}
                            placeholder={"Enter Variation name"} size={'small'} label="Variation Name" bg={Colors.bgShade1}
                         />
                    </div>
                    <div>
                        <Chips value={sizes} onChange={(e) => setSizes(e.value)} />
                    </div>
                    <div style={{marginBottom:"15px", marginTop:"-10px"}}>
                        <PrimaryButton label={"Update Variation"} size="small" callback={()=>0} br="0px" />
                    </div>
                </div>
                <br/>
                <div className="bold h5" style={{width:"100%", height:"40px", backgroundColor:Colors.bg2, padding:"12px", display:'flex', alignItems:'center',  }}>
                    Product Supplier/Vendor: 
                </div>
                <div style={{marginTop:"15px"}}>
                    <Selector label={"Select Vendor"} placeholder="Select Vendor" list={status_list} callback={()=>0} />
                </div>
                <div className="no-padding-on-mobile" style={{paddingLeft:'0px', marginTop:"20px", display:'none'}}>
                    <DataTable 
                        value={items_} showGridlines size="small" stripedRows rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular"headerClassName={'h6 bold table-head'}
                            headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Product" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="price" header="Cost Price" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="medium">{"\u20A6"}{validate.toLocal(p?.price)}</div>)
                            }}
                        ></Column>
                        <Column field="qty" header="Quantity" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head f-w'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}} 
                            body ={(c)=>
                                <Link to="/ad-management/products/0242422" style={{color:Colors.primary}}>View</Link>
                            }
                            ></Column>
                    </DataTable>
                </div>

                
                
            </div>
        </div>
    </div>
    <Dialog visible={open} closeOnEscape  onHide={()=>setOpen(false)}>
         <div >
            <div className='bold h4' style={{color:Colors.black}}>Do you want to mark this order as completely fulfiled?</div>
            <div>Ensure the order has been delivered and confirmed before marking this as closed and complete</div>
            <div style={{marginTop:"30px"}}>
               <PrimaryButton label={"No, Cancel"} callback={()=>setOpen(false)}
                  br="0px" bg={Colors.bgShade1} color={Colors.black}  width="100%" border={"1px solid gray"}
                />
            </div>

            <div style={{marginTop:"20px"}}>
               <PrimaryButton
                  label={"Yes, Order has been fulfiled!"} callback={()=>0} width="100%" br="0px"
                />
            </div>
         </div>
      </Dialog>
    <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.7rem 0.5rem;  }
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    .p-chips-token { background-color: ${Colors.bg2}; border-radius:5px  }
    .p-chips, .p-chips-multiple-container {width:100%; border-radius:0px; }
    .p-chips:not(.p-disabled):hover .p-chips-multiple-container {border-color: ${Colors.primary}}
    `}</style>
</div>
)
}
export default AdminProductDetailsView