import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import CommunityOrders from '../../views/community/orders';


function CommunityOrdersPage(){
return(
   <div>
      <PageBuilder PageComponent={CommunityOrders} title={"Community"} />
   </div>
)
}
export default CommunityOrdersPage;