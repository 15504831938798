
import React, {useEffect, useState} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/button/primary';
import MainSideContentModal from "../../components/modal/slidemodal";
import Input from '../../components/input/input';
import Selector from '../../components/input/select';
import TextArea from '../../components/input/textarea'
import Colors from "../../utils/theme/colors"
import useValidation from '../../utils/helper/validate';
import AdminCreateProduct from './components/create-p';


const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]



function AdminProductsView(){
let [open, setOpen] = useState(false)



let validate = useValidation();



return(
<>
    <div className='inner-content-bag'>
       
      <div className='row no-margin' style={{marginTop:"20px", width:'100%', display:'flex', alignItems:'flex-start', justifyContent:'flex-start', flexWrap:'wrap', clear:'both'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Available in Ibadan</span>
                </div>
        </div>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 10px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Available in Lagos </span>
                </div>
        </div>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 10px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Available in Abuja </span>
                </div>
        </div>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px", marginBottom:"20px"}}>
            <div className='no-margin-on-mobile' style={{marginTop:"-10px"}}>
                <PrimaryButton
                    label={'Create New Product'} callback={()=>setOpen(true)} br="0px" width={'100%'}
                />
            </div>
        </div>
      </div>

        <div className="no-padding-on-mobile" style={{paddingLeft:'0px', marginTop:'20px', width:"100%"}}>
                    <DataTable 
                        value={items_} showGridlines size="small" stripedRows rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Name" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Description" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Sizes" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="price" header="Variation" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="medium">{"\u20A6"}{validate.toLocal(p?.price)}</div>)
                            }}
                        ></Column>
                        <Column field="qty" header="Price Range" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="qty" header="Status" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="id" header="#" className="h6 regular" headerClassName={'h6 bold table-head f-w'}
                            bodyClassName="tr-body" headerStyle={{borderTopLeftRadius:"2px"}} 
                            body ={(c)=>
                                <Link to="/ad-management/products/0242422" style={{color:Colors.primary}}>View</Link>
                            }
                            ></Column>
                    </DataTable>
        </div>

   </div>
   <AdminCreateProduct open={open} close={()=>setOpen(false)} />
   <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.9rem 0.5rem;  }
    .tr-body {padding:0.7rem 0.5rem; }
    .f-w {max-width:50px}
    `}</style>
</>
)
}
export default AdminProductsView