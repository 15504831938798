import React from 'react';
import ReactDOM from 'react-dom/client';
import {  RouterProvider,} from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import AppStore, {persist_store} from './redux/store/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'animate.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; 
import './styles/bootstrap.min.css'
import './styles/globals.css'
import './styles/Home.module.css'
import './styles/styles.css'
import './styles/responsive.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = AppStore // AppStore();
root.render(
  <React.StrictMode>
    <Provider store ={store}>
      <PersistGate loading={null} persistor={persist_store}>
        <ToastProvider autoDismiss={true} autoDismissTimeout={10000}>
          <RouterProvider router={App} />
        </ToastProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
