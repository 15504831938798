import React, {useEffect, useState, useRef} from "react";
import { createPortal } from 'react-dom';
import { MdCancel,  } from 'react-icons/md';
import Colors from "../../utils/theme/colors";
import ThemeColors from "../../utils/theme/colors";
import PrimaryButton from "../button/primary";



function MainSideContentModal({open, close, size, title, useButton, buttonCallback, buttonTitile, sy, children}){


let xh = useButton ? "119px":"45px"; 
//filter:"blur(8px)", backdropFilter:"blur(10px)",
return(
<div>
{ createPortal( open && 
        <div style={{position:"fixed",  top:'0px', bottom:'0px', width:"100%", height:'100%', backgroundColor:"#00000059", display:'flex', flexDirection:'row', justifyContent:'flex-end', zIndex:100  }}>
            <div 
                className={(size || "col-md-4") + (" animate__animated animate__slideInRight  animate__fast no-padding")} 
                style={{height:"100vh", backgroundColor:"#fff", borderTopLeftRadius:"1px", borderBottomLeftRadius:"1px", overflow:'hidden', display:'flex', flexDirection:'column', justifyContent:'space-between'}}>

                <div style={{height:"50px", width:"100%", padding:"8px 15px", display:'flex', alignItems:'center', justifyContent:'space-between', flexDirection:"row", backgroundColor:ThemeColors.primary1}}>
                    <div className="bold h5" style={{ color:ThemeColors.black}}>
                        {title || "Header Title"}
                    </div>
                    <div 
                        style={{width:"30px", height:"30px", borderRadius:"50%", cursor:"pointer"}}
                        onClick={()=>close()}>
                        <MdCancel size={30} color={ThemeColors.blacked} />
                    </div>
                </div>

                <div style={{width:"100%", height:"calc(100vh - "+ xh + ")", overflowY:sy ||null }}>
                    {children}
                </div>

                {useButton &&
                    <div style={{height:"74px", backgroundColor:Colors.light, width:"100%", padding:"4px 15px", borderTop:"1px solid "+ThemeColors.bgShade1}}>
                        <PrimaryButton 
                            label={buttonTitile || "Create Income"}
                            callback = {()=> buttonCallback? buttonCallback():0 }
                            width="100%" br={"1px"}
                         />
                    </div>
                }
            </div>
        </div>
    , document.body
)}
</div>
)
}
export default MainSideContentModal;