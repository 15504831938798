import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import CommunityActivities from '../../views/community/activities';


function CommunityActivityPage(){
return(
   <div>
      <PageBuilder PageComponent={CommunityActivities} title={"Community"} />
   </div>
)
}
export default CommunityActivityPage;