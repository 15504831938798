import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import MarketPlace from '../../views/market/market-p';


function MarketPlacePage(){
return(
   <div>
      <PageBuilder PageComponent={MarketPlace} title={"Dashboard"} />
   </div>
)
}
export default MarketPlacePage;