import { AUTH_MODAL_REMOVE, AUTH_MODAL_SET } from "../types";

export function activateModal (){
   return {
     type:AUTH_MODAL_SET,
   }
 }

export function removeModal (){
   return {
     type:AUTH_MODAL_REMOVE,
   }
 }

