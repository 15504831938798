
import React from "react";
import { useSearchParams } from "react-router-dom";
import AccountMenu from "./components/acc-menu";
import Input from "../../components/input/input";
import PrimaryButton from "../../components/button/primary";
import Colors from "../../utils/theme/colors";
import AccountProfile from "./views/profile";
import AccountVerification from "./views/verify";
import AccountSecurity from "./views/secure";




function PersonalAccount(){
let [searchParams, setParams] = useSearchParams();
let c = searchParams.get("m");

return(
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"20px"}} >
            <AccountMenu />
        </div>
        <div className="col-md-9 no-padding-on-mobile" style={{padding:"0px 0px 0px 20px", textAlign:'left'}}>
            {(c==null || c=='profile') && <AccountProfile /> }
            {  c=='verify' && <AccountVerification /> }
            {  c=='security' && <AccountSecurity /> }
        </div>
    </div>
</div>
)
}
export default PersonalAccount;