import React from 'react';
//import {useCookies} from 'react-cookie';
import {useDispatch, useSelector} from 'react-redux';
import {addAuth} from '../../redux/actions/authAction';


function useStoreAuthRefresh(){ 
   //Our job is to update auth time after every api call.
   let dispatch = useDispatch();
   let [cookie, setCookie] =[] // useCookies(); 
   if(cookie?.Jaguar ===undefined || cookie.Jaguar==null){ 
      return ()=>1;
   }
   let auth = cookie?.Jaguar;
   auth.time = (new Date()).getTime();
   
   return ()=> {
      setCookie("Jaguar", auth, {path:'/'})
      dispatch(addAuth(auth));
   };
}
export default useStoreAuthRefresh;