

import React, {useEffect, useState} from "react";
import { Timeline } from 'primereact/timeline';
import { useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TbExchange } from "react-icons/tb";
import Colors from "../../utils/theme/colors";
import CommunityMenu from "./components/menus";
import CommunityActivityItem from "./components/act-item";
import useValidation from '../../utils/helper/validate'

const activity_types = ['recived', 'preparing order', 'on route for delivery', 'order delivered'];

const items_ = [
    {id:1, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:2, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:3, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:4, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
    {id:5, item:"Rice", size:"50kg", qty:1, price:50000, total:'50000', },
]


function CommunityOrderDetails(){
let params = useParams();
let validate = useValidation();

function Marker(){

return(
    <div className="flex-center" style={{width:"26px", height:'26px', borderRadius:"50%", backgroundColor:Colors.primary1}}>
        <TbExchange size={14} color={Colors.primary} />
    </div>
)
}
return(
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"30px"}} >
            <CommunityMenu />
        </div>
        <div className="col-md-9 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px"}}>
            <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left' }}>
                <div className='bold h2' style={{color:Colors.black, textDecoration:"underline", padding:"0px 10px 10px 10px"}}>
                    Order: #{params?.order_id} | <span className="h6">Datetime</span>
                </div>
                <div className="row no-margin">
                    <div className="col-md-4 no-padding-on-mobile" style={{padding:"0px 10px", marginTop:"20px"}}>
                        <div className="bold h2" style={{width:"100%", height:"50px", borderRadius:'5px', backgroundColor:Colors.bg2, padding:"12px", display:'flex', justifyContent:'center', color:Colors.black, flexDirection:'column'}}>
                            {"\u20A6"}{validate.toLocal(2424299.48)}
                            <div className="h7 regular" style={{color:Colors.black}}>Total order amount</div>
                        </div>
                    </div>
                </div>
                <div className='bold h5 no-padding-on-mobile' style={{color:Colors.black, textDecoration:"underline", padding:"0px 10px", margin:'25px 0px'}}>
                    Order Items: 
                </div>
                <div className="no-padding-on-mobile" style={{paddingLeft:'10px'}}>
                    <DataTable 
                        value={items_} showGridlines size="small" stripedRows rowHover
                        tableStyle={{ minWidth: '20rem', borderRadius:"2px", overflow:'hidden',  }}>
                        <Column field="id" header="#" className="h6 regular"headerClassName={'h6 bold table-head'}
                            headerStyle={{borderTopLeftRadius:"2px"}}></Column>
                        <Column field="item" header="Item" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="size" header="Price" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="price" header="Price" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="medium">{"\u20A6"}{validate.toLocal(p?.price)}</div>)
                            }}
                        ></Column>
                        <Column field="qty" header="Quantity" className="h6 regular" headerClassName={'h6 bold table-head'}></Column>
                        <Column field="total" header="Total Amount" className="h6 regular" headerClassName={'h6 bold table-head'}
                            body={(p)=>{
                                return (<div className="bold">{"\u20A6"}{validate.toLocal(p?.total)}</div>)
                            }}
                            headerStyle={{borderTopRightRadius:"2px"}}></Column>
                    </DataTable>
                </div>

                <div className='bold h5 no-padding-on-mobile' style={{color:Colors.black, textDecoration:"underline", padding:"20px 10px ", marginTop:"25px"}}>
                    Delivery: 
                </div>

                <div className="no-padding-on-mobile" style={{paddingLeft:"10px", marginTop:'25px'}}>
                    <Timeline align="left" value={activity_types} 
                        content={(item)=>CommunityActivityItem(item, true)} marker={Marker} />
                </div>
            </div>
        </div>
    </div>
    <style>{`
    .table-head {background-color: ${Colors.primary}; color:${Colors.bgLight}; padding:0.7rem 0.5rem;  }
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>
</div>
)
}
export default CommunityOrderDetails;