
import React from "react";
import CommunityMenu from "./components/menus";
import { Timeline } from 'primereact/timeline';
import { HiViewGridAdd } from "react-icons/hi";
import {MdPersonAdd, MdOutlineGroupRemove, MdAddShoppingCart, MdOutlineShoppingCart } from 'react-icons/md'
import { IoPersonRemoveOutline } from "react-icons/io5";
import { GiPayMoney } from "react-icons/gi";
import { TbExchange } from "react-icons/tb";
import Colors from "../../utils/theme/colors";
import CommunityActivityItem from "./components/act-item";


const activity_types = ['new_member', 'member_removed', 'member_leave', 'group_creation', 'new_order', 'order_fulfiled', 'new_contribution', 'group_details_change'];

let activities = [
    {type:"group_creation", title:"Group created", description:"[Group Name] was created by Temilade", datetime:"Aug 10,2024 - 10:00am"},
]


function CommunityActivities(){

function Marker(){

let MarkerIcon = {
    'new_member':MdPersonAdd, 
    'member_removed':IoPersonRemoveOutline, 
    'member_leave':MdOutlineGroupRemove, 
    'group_creation':HiViewGridAdd, 
    'new_order':MdAddShoppingCart, 
    'order_fulfiled':MdOutlineShoppingCart, 
    'new_contribution':GiPayMoney, 
    'group_details_change':TbExchange
}

return(
    <div className="flex-center" style={{width:"26px", height:'26px', borderRadius:"50%", backgroundColor:Colors.primary1}}>
        <HiViewGridAdd size={14} color={Colors.primary} />
    </div>
)
}


return (
<div className='inner-content-bag' >
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-3 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"30px"}} >
            <CommunityMenu />
        </div>
        <div className="col-md-9 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px"}}>
            <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left' }}>
                <div className='bold h3' style={{color:Colors.black, textDecoration:"underline", padding:"0px 10px 20px 10px"}}>
                    Recent Community Activities
                </div>
                <div className="no-padding-on-mobile" style={{paddingLeft:"10px"}}>
                    <Timeline align="left" value={activity_types} content={CommunityActivityItem} marker={Marker} />
                </div>
            </div>
        </div>
    </div>
    <style>{`
    .p-timeline-event-opposite {display:none !important}
    .p-timeline-event-connector {background-color: ${Colors.primary1}}
    `}</style>
</div>
)
}
export default CommunityActivities;


