import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminVendorsView from '../../views/admin/vendors';


function AdminVendorsPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminVendorsView}  />
   </div>
)
}
export default AdminVendorsPage;