import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminPasswordRecoveryOTPView from '../../views/auth/admin/otp';


function AdminPasswordOTPPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminPasswordRecoveryOTPView} title={"Recovery OTP"} />
   </div>
)
}
export default AdminPasswordOTPPage;