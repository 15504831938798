import React, {useEffect} from 'react';
import PrimaryButton from '../../components/button/primary';
import Colors from '../../utils/theme/colors';


function AdminDashboard(){
return(
   <div className='inner-content-bag'>
       
      <div className='row no-margin' style={{marginTop:"20px", width:'100%', display:'flex', alignItems:'flex-start', justifyContent:'flex-start', flexWrap:'wrap', clear:'both'}}>
        <div className="col-md-4 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>All Time Users</span>
                </div>
        </div>
        <div className="col-md-4 no-padding-on-mobile" style={{padding:"0px 10px 0px 10px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>All Communities</span>
                </div>
        </div>
        <div className="col-md-4 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px", marginBottom:"20px"}}>
                <div className="bold h3" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.primary1, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                            {92} | 
                    <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Todays Orders</span>
                </div>
        </div>
      </div>
   </div>
)
}
export default AdminDashboard;