import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminOrdersDashboard from '../../views/admin/orders';


function AdminOrdersPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminOrdersDashboard} title={"Dashboard"} />
   </div>
)
}
export default AdminOrdersPage;