
import { Link } from "react-router-dom";

import Colors from "../../../utils/theme/colors";

function CommunityActivityItem(item, is_orders=false){ //console.log(item)

let path = window.location.pathname;

return(
<div style={{position:"relative", marginBottom:'20px'}}>
    <div style={{width:'20px', height:'5px', position:'absolute', top:"10px", left:"-20px", backgroundColor:Colors.primary1, }}></div>
    <div className="add-box-shadow2-" style={{width:'100%', padding:"10px", borderRadius:"1px", border:"1px solid "+Colors.bgShade1, backgroundColor:Colors.bg}}>
        <div className="medium h5" style={{color:Colors.black}}>Title | <span className="h7">Datetime</span> </div>
        <div className="light h5" style={{color:Colors.black}}>description</div>
        <Link 
            className="h6"
            to={is_orders ? path+"/2408242" :"#" }
            style={{color:Colors.primary, margin:"5px 0px"}}
            >View order details</Link>
    </div>
</div>
)
}
export default CommunityActivityItem;