import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import CommunityMembers from '../../views/community/members';


function CommunityMembersPage(){
return(
   <div>
      <PageBuilder PageComponent={CommunityMembers} title={"Community"} />
   </div>
)
}
export default CommunityMembersPage;