import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminMoreScreen from '../../views/admin/more';


function AdminMorePage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminMoreScreen}  />
   </div>
)
}
export default AdminMorePage;