import React, {FC} from 'react';
import Colors from '../../utils/theme/colors';



function PrimaryButton ({label, bg, color, size, callback, weight, busy, width, Icon, br, border}){
return(
   <button className="button" onClick={busy? null : ()=>callback()}
      style={{
         fontWeight:weight ||500,
         display:'flex',
         justifyContent:'center',
         alignItems:'center',
         borderRadius: br || '5px',
         fontSize:size? "14px" : size=="small"? "12px" : size=="medium" ? "14px" : "16px",
         padding:!size ? "14px" : size=="small"? "10px" : size=="medium" ? "14px" : "18px",
         paddingRight:"1.5em",
         paddingLeft:"1.5em",
         backgroundColor:bg||Colors.primary,
         color:color || Colors.light,
         border:border || 'none',
         minWidth: width || "auto"
      }}
      >
         {Icon? <Icon style={{marginRight:'5px'}} /> :null}
         {busy ? <div id="loading" ></div> :null}
       {label}
   </button>
)
}
export default PrimaryButton;


