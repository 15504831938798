import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminOrderDetailsView from '../../views/admin/order';


function AdminOrderDetailsPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminOrderDetailsView} title={"Dashboard"} />
   </div>
)
}
export default AdminOrderDetailsPage;