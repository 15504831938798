import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminProductDetailsView from '../../views/admin/product';


function AdminProductDetailsPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminProductDetailsView}  />
   </div>
)
}
export default AdminProductDetailsPage;