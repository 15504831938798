import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import useGuard from '../guard/guard';

//Your job is to manage authentication and allow (e.g, borrower can not see market place, etc.)

/*
useGaurd helps to manage authentication. Afterwards, you then check if user type can use a resource;
allow is defined only when a page is restricted to only one user type. in that case, only that user type can allow the page.
deny is defined when a user type cannot allow a page
*/

function AuthenticationManager({children, allow, deny}){
let guard = useGuard();
let navigate = useNavigate();
const auth = useSelector((state)=>state.Auth.Auth); //console.log("Who called me?")

useEffect(()=>{
   if(guard===false) navigate('/')
}, [])

if(!guard){
   return null; //ideally return a nice session expired screen
}

if(allow){
   if(allow==auth.type){
      return(
         <div>
            {children}
         </div>
      )
   }
   else {
      //redirect to dashboard
      navigate("/dashboard")
   }
}

//if allow wasn't set, it means anybody can allow this page.
return(
      <div>
         {children}
      </div>
   )
}
export default AuthenticationManager;