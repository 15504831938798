import React from 'react';
import AdminPageBuilder from '../../utils/helper/ad-pagebuilder';
import AdminPasswordResetView from '../../views/auth/admin/reset';


function AdminPasswordResetPage(){
return(
   <div>
      <AdminPageBuilder PageComponent={AdminPasswordResetView} title={"Reset Password"} />
   </div>
)
}
export default AdminPasswordResetPage;