import React from 'react';

import Colors from '../../utils/theme/colors';
import useColors from '../../utils/theme/useColor';



function CardNormal({radius, children, styles}){
let colors = useColors();

return(
   <div className='add-shadow' style={{
      width:"100%",
      backgroundColor:colors.bgLight, padding:"15px 12px", borderRadius:radius || "5px",
      border:" 1px solid "+Colors.bg2,

      ...styles
      }}>
      {children}
   </div>
)
}
export default CardNormal;