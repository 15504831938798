import { AUTH_REMOVE, AUTH_DO } from "../types";

export function addAuth (data){
   return {
     type:AUTH_DO,
     payload:data
   }
 }

export function removeAuth (){ //this action should reset the store.
   return {
     type:AUTH_REMOVE,
   }
 }

