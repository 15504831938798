
import React from "react";



function AdminMoreScreen(){
return(
    <div></div>
)
}
export default AdminMoreScreen;