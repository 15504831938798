import React from 'react';
import PageBuilder from '../../utils/helper/pagebuilder';
import CommunityOrderDetails from '../../views/community/order-d';


function CommunityOrderDetailsPage(){
return(
   <div>
      <PageBuilder PageComponent={CommunityOrderDetails} title={"Community"} />
   </div>
)
}
export default CommunityOrderDetailsPage;