import React, {useEffect, useState} from "react"

import Selector from "../../components/input/select"
import Colors from "../../utils/theme/colors"
import Input from "../../components/input/input"
import TextArea from '../../components/input/textarea'
import PrimaryButton from "../../components/button/primary"


function MarketPlaceChecout(){
let [name, setName] = useState({})
let [phone, setPhone] = useState({})
let [address, setAddress] = useState({})



return(
<div className='inner-content-bag'>
    <div className="row no-margin" style={{width:'100%'}}>
        <div className="col-md-5 no-padding-on-mobile" style={{padding:"0px 10px 0px 0px", marginBottom:"20px"}}>
            <div className="bold h4" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.bg2, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black,   }}>
                Community | <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Which Community is this order for?</span>
            </div>
            <div style={{marginTop:"20px"}}>
                <Selector />
            </div>
        </div>
        <div className="col-md-7 no-padding-on-mobile" style={{padding:"0px 0px 0px 10px", }}>
            <div className="bold h4" style={{width:"100%", height:"48px", borderRadius:'1px', backgroundColor:Colors.bg2, padding:"15px 12px", display:'flex', alignItems:'center', color:Colors.black, marginBottom:'20px'   }}>
                Delivery | <span className="h6 regular" style={{marginTop:"5px", marginLeft:'5px'}}>Ibadan</span>
            </div>
            <div className="row no-margin">
                <div className='col-md-6 no-padding-on-mobile' style={{padding:"0px 10px 0px 0px" }}>
                    <Input
                        placeholder={"Enter recipient's full name"} label="Who will receive this delivery?" bg={Colors.bgShade1}
                        value={name.v} callback={(v)=>{name.v=v; setName(JSON.parse(JSON.stringify(name)))}}
                        hasError={name.e} errorMessage={name.m}
                    />
                </div>
                <div className='col-md-6 no-padding-on-mobile' style={{padding:"0px 0px 0px 10px", }}>
                    <Input
                        placeholder={"Enter phone number"} label="Recipient's Phone Number" bg={Colors.bgShade1} type="number"
                        value={phone.v} callback={(v)=>{phone.v=v; setPhone(JSON.parse(JSON.stringify(phone)))}}
                        hasError={phone.e} errorMessage={phone.m}
                    />
                </div>

                <div className='col-md-12 no-padding' style={{margin:"15px 0px"}}>
                    <TextArea
                        placeholder={"Enter address of recipient"} label="Detailed Address" bg={Colors.bgShade1}
                        value={address.v} callback={(v)=>{address.v=v; setAddress(JSON.parse(JSON.stringify(address)))}}
                        hasError={address.e} errorMessage={address.m}
                    />
                </div>

                <div>
                    <PrimaryButton label={"Complete Checkout"} br="0px" callback={()=>0}     />
                </div>
            </div>
        </div>
    </div>
</div>
)
}
export default MarketPlaceChecout