import React from 'react';
import { Link } from 'react-router-dom';
import Colors from '../../utils/theme/colors';


const menus = ["home", 'orders', 'vendors', 'products', 'more' ]

function AdminBottomNavigation(){

let path = window.location.pathname;
const base_ = "/ad-management"

return(
<div className='show-on-mobile'>
   <div className='bottom-nav' style={{backgroundColor:Colors.bg2}}>
      {
        menus.map((m, i)=>
            <Link
                key={i} className="nav-menu-item bold h5"
                to={m=='home' ?  base_+'/dashboard' :  base_+"/"+ m}
                style={{backgroundColor: path.indexOf(m=='home' ? 'dashboard' :m) >=0 ? Colors.primary:null,
                    color:  path.indexOf(m=='home' ? 'dashboard' :m) >=0 ? Colors.bgLight:Colors.black,
                }}
            >
                {m}
            </Link>
        )
     }
   </div>
</div>
)
}
export default AdminBottomNavigation;