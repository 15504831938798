
import LoginPage from '../pages/auth/index';
import RegistrationPage from '../pages/auth/signup';
import ForgotPasswordPage from '../pages/auth/forgot';
import ForgotPasswordOTP from '../pages/auth/recovery-otp';
import ForgotPasswordReset from '../pages/auth/reset';
import VerifyEmailPage from '../pages/auth/verify-email'

const AuthRoutes =[
    {
      path: "/",
      element: <LoginPage />,
    },
    {
        path: "/auth/signup",
        element: <RegistrationPage />,
    },
    {
      path: "/auth/forgot-password",
      element: <ForgotPasswordPage />,
   },
   {
    path: "/auth/password-otp",
    element: <ForgotPasswordOTP />,
   },
   {
    path: "/auth/reset-password",
    element: <ForgotPasswordReset />,
   },
   {
    path: "/auth/verify-email",
    element: <VerifyEmailPage />,
   },
    
    
  ];
  
  export default AuthRoutes;