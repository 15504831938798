
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Selector from "../../../components/input/select";
import Colors from "../../../utils/theme/colors";


const cats = require("../../../utils/data/product-categories.json");

function ProductCategory(){

let [searchParams, setParams] = useSearchParams();

let params = useParams();
let path = window.location.pathname;
let c = searchParams.get("c");

function testActive(m){
    if(m=='all' && c==null) return true
    return c==m;
}

return(
    <div style={{width:"100%", border:"0px solid "+Colors.bgShade1, borderRadius:'5px', textAlign:'left', }}>
        <div className='bold h5' style={{color:Colors.black, textDecoration:"underline", padding:"0px 10px 12px 0px"}}>Filter:</div>

        <div style={{marginTop:'5px', paddingBottom:"0px",}} className="show-on-mobile">
            <Selector
                    list={cats} callback = {(c)=>setParams({c:c.value})}
            />
        </div>
        <div style={{marginTop:'5px', paddingBottom:"0px"}} className="hide-on-mobile">
            {
                cats.map((m, i)=>
                    <Link
                        key={i}
                        to = { m.value=='all' ? "#": path +"?c="+m.value  }
                        className={ testActive(m.value) ? "medium h5 sub-menu-item-active" : "medium h5 sub-menu-item" }
                        style={{
                            display:'block', textAlign:'left', alignItems:'center', display:'flex', padding:"10px 10px",
                            textTransform:'capitalize'
                        }}
                    >
                        {m.label}
                    </Link>
                )
            }
        </div>
    </div>
)
}
export default ProductCategory;