import {useSelector, useDispatch} from 'react-redux';
import { activateModal } from '../../redux/actions/am';
import useStoreAuthRefresh from '../guard/useStoreAuthRefresh';

import useAlert from './toast';

function useApi(){
   let auth = useSelector((state)=>state?.Auth?.Auth);
   let apiBase = process.env.REACT_APP_BASE_URL; 
   let refresh = useStoreAuthRefresh();
   let alert = useAlert();
   let dispatch = useDispatch();
   return {

   get:async(url, payload)=>{
      let arg = Object.entries(payload).map((i, ii)=> ii==0? i[0]+"="+i[1] : "&"+ i[0] +"="+ i[1]).join("");
      let mid = auth ==null || auth.m==null ? '' : auth.m.mid;
      let uem = auth==null || !auth.user || auth.user==null ? "": auth.user.email;
      let request= await fetch(apiBase + url+"?"+arg+"&mid="+mid+"&user="+uem,
         { method:'GET', 
           headers:{"Authorization": auth.token} }
           )
         .then(response=>response.json())
         .catch((e)=>e.message); 
      if(!request.status){
         return {status:"error", message: typeof(request)=="string" ? request : JSON.stringify(request)}
      }
      //we update auth time everytime there is an api call
      //refresh(); //Removing this because we don't have a strong use case for it
      return request;
   },
   post:async(url, payload)=>{ 
      let request= await fetch(apiBase + url,
         { method:'POST', 
           body: JSON.stringify(payload),
           headers:{"Content-Type":"application/json", "Authorization":auth==null? '' : auth.token} }
           )
         .then(response=>response.json())
         .catch((e)=> e.message); //console.log(request);
      if(!request || !request.status){
         return {status:"error", message: typeof(request)=="string" ? request : JSON.stringify(request)}
      }
      if(request.status && request.status=="login"){
         //Request login action and still send the result
         dispatch(activateModal())
         return {status:"error", message: request.message}
      }
      //refresh();
      return request;
   },
    
}
}
export default useApi;