import { combineReducers } from "redux";
import AuthReducer from "./auth";
import AuthModalReducer from "./authModal";
//import AccountReducer from "./accountReducer";


const rootReducer = combineReducers(
   {
      Auth:AuthReducer,
      AM:AuthModalReducer,
      //Account:AccountReducer,
   }
);

export default rootReducer;